import { createAction, props } from '@ngrx/store';
import { Unit } from 'src/app/shared/models/unit';

export const initUnits = createAction('[WITA] Init Units');

export const loadingUnits = createAction(
  '[WITA] Loading Units'
);
export const loadUnitsSuccess = createAction(
  '[WITA] Load Units Success',
  props<{ units: Unit[] }>()
);
export const loadingUnitsError = createAction(
  '[WITA] Loading Units Error'
);
