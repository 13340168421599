import { createAction, props } from '@ngrx/store';
import { MonitoringElement } from 'src/app/shared/models/monitoringElement';

export const initBeds = createAction('[OVERVIEW_BEDS_PAGE] Init beds');
export const loadingBeds = createAction(
  '[OVERVIEW_BEDS_PAGE] Loading Beds'
);
export const loadingBedsSuccess = createAction(
  '[OVERVIEW_BEDS_PAGE] Load Beds Success',
  props<{ beds: MonitoringElement[] }>()
);
export const loadingBedsError = createAction(
  '[OVERVIEW_BEDS_PAGE] Loading Beds Error'
);
export const clearSelectedBeds = createAction(
  '[OVERVIEW_BEDS_PAGE] Clear selected beds'
)
export const addSelectedBed = createAction(
  '[OVERVIEW_BEDS_PAGE] Add selected bed',
  props<{ bedId: string }>()
)
export const removeSelectedBed = createAction(
  '[OVERVIEW_BEDS_PAGE] Remove selected bed',
  props<{ bedId: string }>()
)
