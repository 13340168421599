<ion-app>
  <ion-router-outlet></ion-router-outlet>
  <ion-popover
    #popoverDeviceOffline
    [isOpen]="deviceOffline"
    (didDismiss)="deviceOffline = false"
    [alignment]="'center'"
    [backdropDismiss]="false"
    [dismissOnSelect]="false"
    [translucent]="true"
  >
    <ng-template>
      <app-device-is-offline></app-device-is-offline>
    </ng-template>
  </ion-popover>
</ion-app>
