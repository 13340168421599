<app-navigation-top-toolbar
  [title]="'PAGES.SETTINGS.TITLE' | translate"
></app-navigation-top-toolbar>

<ion-content [fullscreen]="true" color="light">
  <div style="display: flex;flex-direction: column;justify-content: center;text-align: center;margin:20px;">
    <ion-list style="border-radius: 6px;">
      <ion-item 
        button
        detail="true"
        [ngClass]="'settings-item-button'">
        <ion-label class="fakelink" (click)="navToSettingsPage('account')">{{'PAGES.SETTINGS.ACCOUNT_SETTINGS.TITLE' | translate}}</ion-label>
      </ion-item>
      <ion-item
        button
        detail="true"
        [ngClass]="'settings-item-button'">
        <ion-label class="fakelink" (click)="navToSettingsPage('infrastructure')">{{'PAGES.SETTINGS.MONITORING_SETTINGS.TITLE' | translate}}</ion-label>
      </ion-item>
      <ion-item
        button
        detail="true"
        lines="none"
        [ngClass]="'settings-list-items'">
        <ion-label class="fakelink" (click)="navToSettingsPage('notifications')">{{'PAGES.SETTINGS.NOTIFICATIONS_SETTINGS.TITLE' | translate}}</ion-label>
      </ion-item>
    </ion-list>
  </div>
</ion-content>
