<ion-content [fullscreen]="true">
  <div ngClass="login-outer-container">
    <div ngClass="login-logo-container">
      <ion-icon name="logo-mastodon" aria-hidden="true" ngClass="login-logo"></ion-icon>
    </div>
    <div ngClass="login-form">
      <div ngClass="login-form_inner-container">        
        <!-- LANGUAGE SELECTOR -->
        <div ngClass="login-form_language-container" *ngIf="!hasForgotPw && selectedLangObject">
          <mat-form-field>
            <mat-select [(value)]="selectedLang" (valueChange)="onLanguageSelect($event)">
              <mat-select-trigger>
                <div ngClass="login-form_language-container_trigger-container">
                  <img [src]='selectedLangObject.path'>
                </div>
              </mat-select-trigger>
              <mat-option *ngFor="let country of countriesList" [value]="country.id">
                <img [src]='country.path' style="width: 100%; padding: 5px;">
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- HEADER -->
        <div ngClass="login-form_header">
          <ion-label>
            <h1>
              {{(hasForgotPw ? 'PAGES.LOGIN.RESET_PW_TITLE' : 'PAGES.LOGIN.TITLE') | translate}}
            </h1>
          </ion-label>
          <ion-label>
            <h3>
              {{(hasForgotPw ? 'PAGES.LOGIN.RESET_PW_SUBTITLE' : 'PAGES.LOGIN.SUBTITLE') | translate}}
            </h3>
          </ion-label>
        </div>
        <ng-container [ngSwitch]="hasForgotPw">
          <!-- RESET PW FORM -->
          <form [formGroup]="resetPwForm" ngClass="login-form_inputs" style="margin-bottom: auto;" *ngSwitchCase="true">
            <div [ngClass]="'field-container'">
              <ion-input
                [label]="'PAGES.LOGIN.FORM.EMAIL.LABEL' | translate"
                fill="solid"
                labelPlacement="floating"
                [placeholder]="'PAGES.LOGIN.FORM.EMAIL.PLACEHOLDER' | translate"
                type="email"
                formControlName="email"
                class="login-field"
                [errorText]="'GENERAL.FORM.VALIDATOR_ERRORS.INVALID_EMAIL' | translate"
              >
                <ion-icon name="mail-outline" aria-hidden="true" ngClass="field-icon" slot="start"></ion-icon>
              </ion-input>
            </div>
          </form>
          <!-- LOGIN FORM -->
          <ng-container *ngSwitchDefault>
            <form [formGroup]="authForm" ngClass="login-form_inputs">
              <div [ngClass]="'field-container'">
                <ion-input
                  [label]="'PAGES.LOGIN.FORM.USERNAME.LABEL' | translate"
                  fill="solid"
                  labelPlacement="floating"
                  [placeholder]="'PAGES.LOGIN.FORM.USERNAME.PLACEHOLDER' | translate"
                  type="email"
                  formControlName="username"
                  class="login-field"
                  [errorText]="'GENERAL.FORM.VALIDATOR_ERRORS.INVALID_USERNAME' | translate"
                >
                  <ion-icon name="person-outline" aria-hidden="true" ngClass="field-icon" slot="start"></ion-icon>
                </ion-input>
              </div>
              <div [ngClass]="'field-container'">
                <ion-input
                  [label]="'PAGES.LOGIN.FORM.PASSWORD.LABEL' | translate"
                  labelPlacement="floating"
                  [placeholder]="'PAGES.LOGIN.FORM.PASSWORD.PLACEHOLDER' | translate"
                  [type]="showPassword ? 'text' : 'password'"
                  formControlName="password"
                  class="login-field"
                  color="primary"
                  [errorText]="'GENERAL.FORM.VALIDATOR_ERRORS.INVALID_PASSWORD' | translate"
                >
                  <ion-icon name="lock-closed-outline" aria-hidden="true" ngClass="field-icon" slot="start"></ion-icon>
                  <ion-button fill="clear" slot="end" aria-label="Show/hide" (click)="showPassword = !showPassword">
                    <ion-icon slot="icon-only" [name]="showPassword ? 'eye-off-outline' : 'eye-outline'" aria-hidden="true"></ion-icon>
                  </ion-button>
                </ion-input>
              </div>
            </form>
            <!-- FORGOT PW -->
            <div ngClass="login-form_forgot-pw">
              <a (click)="hasForgotPw = true">{{'PAGES.LOGIN.FORGOT_PASSWORD' | translate}}</a>
            </div>
          </ng-container>
        </ng-container>    
        <!-- CTA -->
        <ng-container [ngSwitch]="hasForgotPw">
          <div ngClass="login-form_multiple-buttons" *ngSwitchCase="true">
            <ion-button (click)="onBack()" color="medium">
              {{'GENERAL.COMMON.BACK' | translate}}
            </ion-button>
            <ion-button (click)="submitMail()">
              {{'GENERAL.COMMON.RESET' | translate}}
            </ion-button>
          </div>
          <div ngClass="login-form_footer" *ngSwitchDefault>
            <ion-button (click)="onClickLogin()">
              {{'GENERAL.COMMON.LOGIN' | translate}}
            </ion-button>
          </div>
        </ng-container>
        <!-- COPYRIGHT -->
        <div ngClass="login-form_copyright">
          <p>{{'GENERAL.COPYRIGHT' | translate}}</p>
        </div>
      </div>
    </div>
  </div>
</ion-content>
