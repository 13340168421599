import { Component, inject } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { Store, select } from '@ngrx/store';
import { switchMap, of, Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';

import { MonitoringService } from 'src/app/core/services/monitoring.service';
import { MonitoringTileComponent } from 'src/app/shared/components/monitoring-tile/monitoring-tile.component';
import { MonitoringElement } from 'src/app/shared/models/monitoringElement';
import { selectCriticalityElements } from 'src/app/state/selectors/criticalityElements.selectors';
import { RefreshErrorToolComponent } from 'src/app/shared/components/refresh-error-tool/refresh-error-tool.component';
import { CriticalityElementsState } from 'src/app/shared/models/state/criticalityElementsState';
import { MonitoringTile } from 'src/app/shared/models/monitoringTile';
import { RiskStatusLevelIcons } from 'src/app/shared/enums/risk-status-level-icons';

@Component({
  selector: 'app-criticalitytab',
  templateUrl: 'criticality-tab.page.html',
  styleUrls: ['criticality-tab.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, MonitoringTileComponent, RefreshErrorToolComponent],
})
export class CriticalityTabPage {

  private monitoringService: MonitoringService = inject(MonitoringService)

  private store = inject(Store)
  private router = inject(Router)
  elements: MonitoringTile[]
  loadingElements: boolean
  hasError: boolean

  constructor() {
    this.elements = []
    this.loadingElements = false
    this.hasError = false
  }

  ionViewDidEnter() {
    this.loadElements()
  }

  private destroyed$ = new Subject()

  ngOnInit() {
    this.store.pipe(
      takeUntil(this.destroyed$),
      select(selectCriticalityElements),
      switchMap((val: CriticalityElementsState) => {
        this.elements = val.data.map((element: MonitoringElement) => ({
          id: element.elementId,
          label: element.name,
          isOnline: false, //@TODO dove recuperare il dato?
          visitorName: element.person?.name || "",
          unit: element.unit.name || "",
          room: element.room.name || "",
          eventTime: "", //@TODO dove recuperare il dato?
          pinned: true, //@TODO dove recuperare il dato?
          riskStatusLevel: element.riskStatusLevel,
          riskStatusLevelIcon: element.riskStatusLevel ? RiskStatusLevelIcons[element.riskStatusLevel as keyof typeof RiskStatusLevelIcons] : ""
        }))
        this.loadingElements = val.isLoading
        this.hasError = val.hasError || false
        return of(val)
      })
    ).subscribe()
  }

  ngOnDestroy() {
    this.destroyed$.next(1)
    this.destroyed$.complete()
  }

  loadElements() {
    this.monitoringService.loadMonitoringElements()
  }

  handlePressElement(event: any) {
    let element = this.elements.find(element => element.id === event);
    this.router.navigate(['/wita/appMonitoring/monitoringRoot/sensors/bed', { id: element?.id }])
  }

  handleSelectElement(event: any) {
    let element = this.elements.find(element => element.id === event);
    console.log(element)
    console.log("Element selected")
  }

}
