import { createAction, props } from '@ngrx/store';

export const initRooms = createAction('[WITA] Init rooms');
export const loadingRooms = createAction(
  '[OVERVIEW_ROOMS_PAGE] Loading Rooms'
);
export const clearSelectedRooms = createAction(
  '[OVERVIEW_ROOMS_PAGE] Clear selected rooms'
)
export const addSelectedRoom = createAction(
  '[OVERVIEW_ROOMS_PAGE] Add selected rooms',
  props<{ roomId: number }>()
)
export const removeSelectedRoom = createAction(
  '[OVERVIEW_ROOMS_PAGE] Remove selected rooms',
  props<{ roomId: number }>()
)
