import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CRITICALITY_ELEMENTS_FEATURE_KEY } from '../reducers/criticalityElements.reducer';
import { CriticalityElementsState } from 'src/app/shared/models/state/criticalityElementsState';

export const selectCriticalityElementsState = createFeatureSelector<CriticalityElementsState>(CRITICALITY_ELEMENTS_FEATURE_KEY);

export const selectCriticalityElements = createSelector(
  selectCriticalityElementsState,
  (state: CriticalityElementsState) => state
);
