import { Component, OnInit, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-retry-error-tool-small',
  templateUrl: './retry-error-tool-small.component.html',
  styleUrls: ['./retry-error-tool-small.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule]
})
export class RetryErrorToolSmallComponent  implements OnInit {

  @Input()
  hasError: boolean
  @Input()
  errorText: string | null
  @Input()
  isLoading: boolean
  @Input()
  loadRetryFn: any

  constructor() {
    this.hasError = false
    this.errorText = null
    this.isLoading = false
  }

  ngOnInit() {}

}
