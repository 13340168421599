import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USER_FEATURE_KEY } from '../reducers/user.reducer';
import { UserState } from 'src/app/shared/models/state/userState';

export const selectUserState = createFeatureSelector<UserState>(USER_FEATURE_KEY);

// const { selectAll } = usersAdapter.getSelectors();

export const selectUser = createSelector(
  selectUserState,
  (state: UserState) => state
);

// export const selectUsersError = createSelector(
//   selectUserState,
//   (state: User) => state.error
// );

// export const selectUser = createSelector(
//   selectUserState,
//   (state: UsersState) => selectAll(state)
// );