import { Component, OnInit, Input, inject } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { SideMenuActionButton } from '../../models/side-menu-action-button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule]
})
export class ActionButtonComponent  implements OnInit {
  
  @Input()
  actionButton: SideMenuActionButton;
  @Input()
  callbackFn: any

  private router: Router = inject(Router)

  constructor() { 
    this.actionButton = {
      label: "empty",
      icon: "empty",
      path: "empty"
    }

    this.callbackFn = null
  }

  ngOnInit() {}

  navigateOn(path: string) {
    this.router.navigate([path])
    if (this.callbackFn) {
      this.callbackFn()
    }
  }

}