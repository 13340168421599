<app-navigation-top-toolbar
  [title]="'Seleziona struttura'"
></app-navigation-top-toolbar>
<ion-content color="light">
  <div [ngClass]="'settings-items-container'">
    <ion-list [inset]="true" [ngClass]="'settings-list-items'">
      <ion-item
        *ngFor="let structure of this.structuresList; let i = index;"
        button
        [detail]="false"
        (click)="onStructurePress(structure.value)"
      >
        <ion-label>{{structure.text}}</ion-label>
        <ion-icon *ngIf="this.selectedStructure === structure.value" name="checkmark-outline" slot="end" color="primary"></ion-icon>
      </ion-item>
    </ion-list>
  </div>
</ion-content>
