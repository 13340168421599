<ion-content [fullscreen]="true" class="page-content" color="light">
  <div class="page-content">
    <app-refresh-error-tool
      [hasError]="this.hasError"
      [isLoading]="this.loadingElements"
      [handleRefreshCallbackFn]="this.loadElements.bind(this)"
      [loadRetryFn]="this.loadElements.bind(this)"
    ></app-refresh-error-tool>
    <ion-list lines="none" *ngFor="let element of elements; let i = index" class="list">
      <app-monitoring-tile
        [tileData]="element"
        [tilesSelectionMode]="false"
        [enableVisualMonitoring]="true"
        [showBodyInfos]="true"
        (handlePressTile)="handlePressElement($event)"
        (handleSelectTile)="handleSelectElement($event)"
      ></app-monitoring-tile>
    </ion-list>
  </div>
</ion-content>
