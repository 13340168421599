import { Component, OnInit, Input, inject } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { NavigationService } from 'src/app/core/services/navigation-service.service';

@Component({
  selector: 'app-navigation-top-toolbar',
  templateUrl: './navigation-top-toolbar.component.html',
  styleUrls: ['./navigation-top-toolbar.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule]
})
export class NavigationTopToolbarComponent  implements OnInit {

  @Input()
  title: string

  private router = inject(Router)
  private navigationService = inject(NavigationService)

  constructor() {
    this.title = ""
  }

  ngOnInit() {}

  onPressBackButton() {
    this.navigationService.back()
  }

}
