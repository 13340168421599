import { Component, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-main-top-toolbar',
  templateUrl: './main-top-toolbar.component.html',
  styleUrls: ['./main-top-toolbar.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule]
})
export class MainTopToolbarComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
