import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BEDS_FEATURE_KEY } from '../reducers/beds.reducer';
import { BedsState } from 'src/app/shared/models/state/bedsState';

export const selectBedsState = createFeatureSelector<BedsState>(BEDS_FEATURE_KEY);

export const selectBeds = createSelector(
  selectBedsState,
  (state: BedsState) => ({
    list: state.list,
    isLoading: state.isLoading,
    hasError: state.hasError
  })
);

export const selectSelectedBeds = createSelector(
  selectBedsState,
  (state: BedsState) => ({
    selectedBeds: state.selectedBeds
  })
);
