import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CustomValidator } from 'src/app/shared/utils/custom-validators';
import { ToastService } from 'src/app/core/services/toast.service';
import { IonicColors } from 'src/app/shared/enums/ionic-colors.enum';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.page.html',
  styleUrls: ['../login/login.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class ResetPasswordPage implements OnInit {
  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  public isSubmitted: boolean = false;
  resetPwForm: FormGroup= new FormGroup<any>({});
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    
  }

  ngOnInit() {
    this.resetPwForm = this.formBuilder.group({
      password: ['', [Validators.required, CustomValidator.passwordValidator]],
      confirmPassword: ['', [Validators.required, CustomValidator.passwordValidator]]
    },{
      validators: CustomValidator.matchPassword // Check whether our password and confirm password match
    });
  }

  goToLogin() {
    this.router.navigate(['/wita/auth/login']);
  }

  submitPassword() {
    if (this.resetPwFormControls['password']['errors'] || this.resetPwFormControls['confirmPassword']['errors'] || this.resetPwForm.invalid) {
      this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.UNCORRECT_FIELDS_COMPILATION'), IonicColors.RED);
      return;
    }
    console.log('Password Submitted')
  }

  get resetPwFormControls() {
    return this.resetPwForm.controls
  }

}
