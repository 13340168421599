import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { IonicColors } from 'src/app/shared/enums/ionic-colors.enum';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private toastController: ToastController
  ) { }

  /**
   * @description Funzione che si occupa di mostrare un toast di Ionic, vedi descrizione parametri per ulteriori informazioni
   * @param message Testo del toast
   * @param color Colore del toast, prendere come riferimento l'enum IonicColors
   * @param duration Definisce la durata di visualizzazione del toast
   */
  async showToast(message: string, color: IonicColors = IonicColors.GREEN, duration: number = 3000) {
    const toast = await this.toastController.create({
        message,
        duration,
        position: "bottom",
        color,
      });
    await toast.present();
  }
}