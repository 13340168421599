import { Component, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { switchMap, of, Subject, takeUntil } from 'rxjs';

import { NavigationTopToolbarComponent } from 'src/app/shared/components/navigation-top-toolbar/navigation-top-toolbar.component';
import { ListOptionsElement } from 'src/app/shared/models/list-options-element';
import { InfrastructureService } from 'src/app/core/services/infrastructure.service';
import { Organization } from 'src/app/shared/models/organization';
import { selectOrganizations } from 'src/app/state/selectors/organizations.selectors';
import { OrganizationsState } from 'src/app/shared/models/state/organizationsState';
import { UserService } from 'src/app/core/services/user.service';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { UserState } from 'src/app/shared/models/state/userState';
import { SharedModule } from 'src/app/shared/modules/shared.module';

@Component({
  selector: 'app-infrastructure-select',
  templateUrl: './infrastructure-select.page.html',
  styleUrls: ['./infrastructure-select.page.scss'],
  standalone: true,
  imports: [SharedModule, NavigationTopToolbarComponent]
})
export class InfrastructureSelectPage {

  structuresList: ListOptionsElement[];
  selectedStructure: string;

  private infrastructureService: InfrastructureService = inject(InfrastructureService);
  private userService: UserService = inject(UserService);

  constructor() {

    this.organizations = [];
    this.loadingOrganizations = false;
    this.hasError = false;

    this.selectedStructure = "";
    this.structuresList = [];
  }

  private store = inject(Store);
  organizations: Organization[];
  loadingOrganizations: boolean;
  hasError: boolean;

  private destroyed$ = new Subject();

  ionViewWillEnter() {

    //connect to user state
    this.store.pipe(
      takeUntil(this.destroyed$),
      select(selectUser),
      switchMap((val: UserState) => {
        this.selectedStructure = val.data && val.data.organizationId ? val.data.organizationId.toString() : ""
        return of(val)
      })
    ).subscribe();

    //connect to organizations state
    this.store.pipe(
      takeUntil(this.destroyed$),
      select(selectOrganizations),
      switchMap((val: OrganizationsState) => {
        this.structuresList = val.data ? val.data.map(organization => ({
          value: organization.id.toString(),
          text: organization.name
        })) : []
        return of(val)
      })
    ).subscribe();

    this.userService.loadUserInfos();
    this.infrastructureService.loadOrganizations();
  }

  ionViewWillLeave() {
    this.destroyed$.next(1);
    this.destroyed$.complete();
  }

  onStructurePress(value: string) {
    this.selectedStructure = value;
    //@TODO dispatch selection and dispatch new load user infos
  }

}
