import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SideMenuComponent } from 'src/app/shared/components/side-menu/side-menu.component';

@Component({
  selector: 'app-monitoring-root',
  templateUrl: './monitoring-root.page.html',
  styleUrls: ['./monitoring-root.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, SideMenuComponent]
})
export class MonitoringRootPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
