<app-navigation-top-toolbar
  [title]="'PAGES.SETTINGS.ACCOUNT_SETTINGS.TITLE' | translate"
></app-navigation-top-toolbar>
<ion-content color="light">
  <div [ngClass]="'settings-items-container'">
    <div class="form_container">
      <div [ngClass]="['container-photo']">
        <ion-icon name="person-outline" aria-hidden="true" ngClass="icon"></ion-icon>
      </div>
      <form *ngIf="!!accountSettingsFg" [formGroup]="accountSettingsFg" ngClass="login-form_inputs">
        <div [ngClass]="'field-container'">
            <ion-input
              [label]="'PAGES.LOGIN.FORM.EMAIL.LABEL' | translate"
              labelPlacement="stacked"
              [placeholder]="'PAGES.LOGIN.FORM.EMAIL.PLACEHOLDER' | translate"
              type="email"
              formControlName="email"
              class="login-field"
              color="primary"
              [errorText]="'GENERAL.FORM.VALIDATOR_ERRORS.REQUIRED_VALUE' | translate"
            >
              <ion-icon name="mail-outline" aria-hidden="true" ngClass="field-icon" slot="start"></ion-icon>
            </ion-input>
        </div>
        <div [ngClass]="'field-container'">
            <ion-input
              [label]="'PAGES.SETTINGS.ACCOUNT_SETTINGS.FORM.USER.FIRSTNAME' | translate"
              labelPlacement="stacked"
              [placeholder]="'PAGES.SETTINGS.ACCOUNT_SETTINGS.FORM.USER.FIRSTNAME_PLACEHOLDER' | translate"
              type="text"
              formControlName="firstname"
              class="login-field"
              color="primary"
              [errorText]="'GENERAL.FORM.VALIDATOR_ERRORS.REQUIRED_VALUE' | translate"
            >
              <ion-icon name="person-outline" aria-hidden="true" ngClass="field-icon" slot="start"></ion-icon>
            </ion-input>
        </div>
        <div [ngClass]="'field-container'">
            <ion-input
              [label]="'PAGES.SETTINGS.ACCOUNT_SETTINGS.FORM.USER.LASTNAME' | translate"
              labelPlacement="stacked"
              [placeholder]="'PAGES.SETTINGS.ACCOUNT_SETTINGS.FORM.USER.LASTNAME_PLACEHOLDER' | translate"
              type="text"
              formControlName="lastname"
              class="login-field"
              color="primary"
              [errorText]="'GENERAL.FORM.VALIDATOR_ERRORS.REQUIRED_VALUE' | translate"
            >
              <ion-icon name="person-outline" aria-hidden="true" ngClass="field-icon" slot="start"></ion-icon>
            </ion-input>
        </div>
      </form>
    </div>
    <ion-button
      fill="clear"
      size="small"
      [ngClass]="'button-unlink-device'"
      (click)="requestAction('resetPassword')"
    >{{ 'PAGES.SETTINGS.ACCOUNT_SETTINGS.RESET_PASSWORD' | translate}}</ion-button><br>
    <ion-button
      fill="clear"
      size="small"
      [ngClass]="'button-unlink-device'"
      id="open-modal"
    >{{ 'Visualizza dispositivi connessi' | translate }}</ion-button><br>
    <ion-button
      fill="clear"
      size="small"
      [ngClass]="'button-unlink-device'"
      (click)="requestAction('logout')"
    >{{ 'PAGES.SETTINGS.ACCOUNT_SETTINGS.LOGOUT' | translate }}</ion-button><br>
    <ion-button
      fill="clear"
      size="small"
      color="danger"
      [ngClass]="'button-unlink-device'"
      (click)="requestAction('deleteAccount')"
    >{{ 'PAGES.SETTINGS.ACCOUNT_SETTINGS.DELETE_ACCOUNT' | translate }}</ion-button>
  </div>
</ion-content>

<ion-modal trigger="open-modal" (willDismiss)="onWillDismiss($event)" class="unlink-devices-modal">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{'PAGES.SETTINGS.ACCOUNT_SETTINGS.LINKED_DEVICES_LIST' | translate}}</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="closeModal()">{{'GENERAL.COMMON.CLOSE' | translate}}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <div ngClass="unlink-devices-modal_container">
        <ion-list>
          <ion-item>
            <div ngClass="unlink-devices-modal_container_list-item">
              <ion-chip>{{'Dispositivo corrente'}}</ion-chip>
              <ion-button (click)="closeModal()" color="danger">{{'GENERAL.COMMON.UNLINK' | translate}}</ion-button>
            </div>
          </ion-item>
          <ion-item *ngFor="let device of userDevices">
            <div ngClass="unlink-devices-modal_container_list-item">
              <ion-chip>
                <ion-avatar>
                  <img [alt]="device.os" src="../../../../assets/files/operative-systems/{{device.os}}.svg" />
                </ion-avatar>
                <ion-label>{{device.os}}</ion-label>
              </ion-chip>
              <ion-chip>{{'PAGES.SETTINGS.ACCOUNT_SETTINGS.LAST_ACCESS' | translate:{date: device.lastTimeOnline} }}</ion-chip>
              <ion-button (click)="requestAction('disconnectAllDevices', device)" color="danger">{{'GENERAL.COMMON.UNLINK' | translate}}</ion-button>
            </div>
          </ion-item>
        </ion-list>        
        <div ngClass="unlink-devices-modal_container_button-container">
          <ion-button (click)="requestAction('disconnectAllDevices')" color="danger" expand="block">{{'GENERAL.COMMON.UNLINK_ALL' | translate}}</ion-button>
        </div>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>