<ion-header [ngClass]="'ion-no-border'">
  <ion-toolbar [ngClass]="'toolbar'">
    <ion-buttons slot="start">
      <ion-menu-button auto-hide="false"></ion-menu-button>
    </ion-buttons>
    <ion-title>Mentorage</ion-title>
    <ion-buttons slot="end">
      <ion-icon name="volume-mute-outline" size="large" class="icon-sound"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>