import { createReducer, on } from '@ngrx/store';

import * as CriticalityElementsActions from '../actions/criticalityElements.actions';
import { CriticalityElementsState } from 'src/app/shared/models/state/criticalityElementsState';

export const CRITICALITY_ELEMENTS_FEATURE_KEY = 'criticalityElements';

export const initialCriticalityElementsState: CriticalityElementsState = {
  data: [],
  isLoading: false,
  hasError: false
};

export const criticalityElementsReducer = createReducer(
  initialCriticalityElementsState,
  on(CriticalityElementsActions.loadingCriticalityElements, state => {
    return ({
      ...state,
      data: [],
      isLoading: true,
      hasError: false
    })
  }),
  on(CriticalityElementsActions.loadingCriticalityElementsSuccess, (state, action) => {
    return({
      ...state,
      data: action.elements,
      isLoading: false,
      hasError: false
    })
  }),
  on(CriticalityElementsActions.loadingCriticalityElementsError, state => {
    return ({
      ...state,
      data: [],
      isLoading: false,
      hasError: true
    })
  }),
);
