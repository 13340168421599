import { Injectable, inject } from "@angular/core";
import { HttpEvent, HttpInterceptorFn, HttpHandlerFn, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { throwError, Observable, BehaviorSubject, of } from "rxjs";
import { catchError, filter, take, switchMap, finalize } from "rxjs/operators";

import { AuthService } from "src/app/core/services/auth.service";
import { ToastService } from "src/app/core/services/toast.service";
import { IonicColors } from "../enums/ionic-colors.enum";
import { TranslateService } from "@ngx-translate/core";

export const witaServiceInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {


    if (!req.headers.has('Content-Type')) {
        req = req.clone({
            headers: req.headers.set('Content-Type', 'application/json')
        });
    }

    // Se la chiamata in oggetto è quelli di auth allora la mando skippando tutta la logica di aggiunta token
    if (req.url.includes("/auth") && !req.url.includes("/auth/")) {
        return next(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error && error.status === 401) {
                    // 401 unauthorized, invalid credentials
                    return throwError(() => "Username o password errati")
                } else {
                    return throwError(() => error);
                }
            })
        );
    }

    let AUTH_HEADER = "Authorization";
    let token: string | boolean = localStorage.getItem('ACCESS_TOKEN') || false;
    let refreshTokenInProgress = false;
    let refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    const refreshTokenEnabled: boolean = false;
    const authService: AuthService = inject(AuthService);
    const toastService = inject(ToastService);
    const translateService = inject(TranslateService);

    req = addAuthenticationToken(req, token, AUTH_HEADER);

    return next(req).pipe(
        catchError((error: HttpErrorResponse) => {
            if (error && error.status === 401) {
                if(!refreshTokenEnabled) {
                    toastService.showToast(translateService.instant('GENERAL.ERRORS.TOKEN_EXPIRED'), IonicColors.RED)
                    authService.appLogout();
                    return throwError(() => error);
                }
                // 401 unauthorized, expired token that need to refresh.
                if (refreshTokenInProgress) {
                    /**
                     * refreshTokenInProgress, wait until refreshTokenSubject has a non-null value
                     * (when not null the new token is ready and we can retry the request again)
                     */
                    return refreshTokenSubject.pipe(
                        filter(result => result !== null),
                        take(1),
                        switchMap(() => next(addAuthenticationToken(req, token, AUTH_HEADER)))
                    );
                } else {
                    refreshTokenInProgress = true;
                    /**
                     * refreshTokenSubject setted to null in order to lock all subsequent API calls
                     * until the new token has been retrieved
                     */
                    refreshTokenSubject.next(null);     
                    return refreshAccessToken().pipe(
                        switchMap((success: boolean) => {               
                            refreshTokenSubject.next(success);
                            return next(addAuthenticationToken(req, token, AUTH_HEADER));
                        }),
                        finalize(() => refreshTokenInProgress = false)
                    );
                }
            } else {
                return throwError(() => error);
            }
        })
    );
}

function addAuthenticationToken(request: HttpRequest<any>, token: string | boolean, AUTH_HEADER: string): HttpRequest<any> {
    //Avoid to add not valid token in the request header
    if (!token) {
        return request;
    }

    /**
     * Avoid to add the token for external domain calls
     * @TODO disabled for now
     */
    // if (!request.url.match(/www.witadomain.com\//)) {
    //   return request;
    // }
    
    return request.clone({
        headers: request.headers.set(AUTH_HEADER, "Bearer " + token)
    });
}

function refreshAccessToken(): Observable<any> {
    return of("new-secret-token");
}