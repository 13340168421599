import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { SegmentedButtonComponent } from '../segmented-button/segmented-button.component';

@Component({
  selector: 'app-camera-screen-detailed',
  templateUrl: './camera-screen-detailed.component.html',
  styleUrls: ['./camera-screen-detailed.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, SegmentedButtonComponent]
})
export class CameraScreenDetailedComponent  implements OnInit {

  @Input()
  bedId: string | null

  toManageEvent: boolean
  selectedCamera: number
  segmentedButtons: Array<any>

  constructor() {
    this.bedId = null
    this.toManageEvent = true
    this.selectedCamera = 0
    this.segmentedButtons = [
      {
        id: 0,
        title: "Stanza",
        icon: "bed-outline",
        color: "#92949c",
        separator: true
      },
      {
        id: 1,
        title: "NC",
        icon: "exit-outline",
        color: "#92949c"
      },
      {
        id: 2,
        title: "DG",
        icon: "scan-outline",
        color: "#ffc409",
        warnable: true
      },
      {
        id: 3,
        title: "MD",
        icon: "bicycle-outline",
        color: "#92949c"
      },
      {
        id: 4,
        title: "CA",
        icon: "body-outline",
        color: "#eb445a",
        warnable: true
      }
    ]
  }

  ngOnInit() {}

  onPressCameraButton(id: number) {
    this.selectedCamera = id
  }

  onPressManageEventButton() {
    this.toManageEvent = false
  }

  onPressPresetsFAB() {
    console.log("edit presets")
  }

  onPressConfigureSensorCamFAB() {
    console.log("configure sensor cam")
  }

}
