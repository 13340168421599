import { Component, EnvironmentInjector, inject } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { Network } from '@capacitor/network';
import { PopoverController } from '@ionic/angular';
import { NavigationService } from './core/services/navigation-service.service';
import { DeviceIsOfflineComponent } from './shared/components/device-is-offline/device-is-offline.component';
import { SwUpdate } from '@angular/service-worker';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, DeviceIsOfflineComponent, TranslateModule]
})
export class AppComponent {
  public environmentInjector = inject(EnvironmentInjector);
  public navigationService = inject(NavigationService)
  public popoverController = inject(PopoverController)
  public authService = inject(AuthService);

  deviceOffline: boolean

  constructor(
    private translateService: TranslateService,
    private swUpdate: SwUpdate
  ) {

    this.deviceOffline = false;

    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status);
      if (status.connected === false) {
        this.deviceOffline = true
      } else {
        this.deviceOffline = false
      }
    });
    
    this.initializeLanguage(navigator.language);
     if(this.authService.isLoggedIn()){
      // all'apertura dell'app con l'utente già loggato, aggiorna il firebase token per sicurezza nel caso in cui sia cambiato
       //this.authService.updateUserDevice$().pipe(take(1)).subscribe(); 
     }
    this.swUpdate.checkForUpdate().then((foundNewVersion) => {
      if (foundNewVersion) {
        confirm(this.translateService.instant('GENERAL.PWA.NEW_VERSION_AVAILABLE')) && window.location.reload();
      }
    });
  }

  /**
   * @description Imposto le traduzioni sulla base della lingua dello user o, se non esistente, del browser, di default metto 'it'
   * @param browserLanguage 
   */
  initializeLanguage(browserLanguage: string) {
    const userLang = typeof localStorage.getItem('currentUser') ===  'string' && JSON.parse(localStorage.getItem('currentUser') as string).userLang;
    if (['it', 'en', 'fr'].includes(userLang)) {
      this.translateService.use(userLang);
    } else {
        if (browserLanguage.includes('en-')) {
          this.translateService.use('en');
        } else if (browserLanguage.includes('fr-')) {
          this.translateService.use('fr');
        } else {
          this.translateService.use('it');
        }
    } 
  }
}