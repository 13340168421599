<div [ngClass]="this.hasError ? 'refresh-error-tools-outer-container' : ''">
  <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <div [ngClass]="'loading-container'" *ngIf="this.isLoading">
    <ion-spinner color="primary"></ion-spinner>
    <ion-label [ngClass]="'loading-label'">Caricamento...</ion-label>
  </div>
  <div *ngIf="this.hasError" [ngClass]="'error-tools-container'">
    <ion-label>Errore di caricamento...</ion-label>
    <ion-button (click)="loadRetryFn()">Riprova</ion-button>
  </div>
</div>