import { Routes } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideState } from '@ngrx/store';

import { AuthRootPage } from './auth-root.page';
import { LoginPage } from '../login/login.page';
import * as fromUserReducer from 'src/app/state/reducers/user.reducer';
import { DisclaimerRequestPage } from '../disclaimer-request/disclaimer-request.page';
import { ResetPasswordPage } from '../reset-password/reset-password.page';
import { witaServiceInterceptor } from 'src/app/shared/interceptors/wita-service-interceptor';

export const AuthRoutes: Routes = [
  {
    path: 'auth',
    component: AuthRootPage,
    providers: [
      provideHttpClient(withInterceptors([witaServiceInterceptor])),
      provideState(fromUserReducer.USER_FEATURE_KEY, fromUserReducer.userReducer),
      //provideEffects(UsersEffects),
    ],
    children: [
      {
        path: 'login',
        component: LoginPage
      },
      {
        path: 'disclaimer',
        component: DisclaimerRequestPage,
      },
      {
        path: 'reset-password',
        component: ResetPasswordPage
      },
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
];
