import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, skip } from 'rxjs';
import { IonicColors } from 'src/app/shared/enums/ionic-colors.enum';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertController: AlertController,
    private translateService: TranslateService
  ) { }

  private handlerSource = new BehaviorSubject(null);
  public handlerResponse = this.handlerSource.asObservable().pipe(skip(1));

  /**
   * @description Funzione che si occupa di mostrare un alert di Ionic, vedi descrizione parametri per ulteriori informazioni
   * @param header Titolo dell'alert
   * @param message Testo dell'alert
   */
  async presentAlert(header: string, message: string) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: [
        {
          text: this.translateService.instant('GENERAL.COMMON.CANCEL'),
          role: 'false',
          handler: () => {
            alert.dismiss(false);
          },
        },
        {
          text: this.translateService.instant('GENERAL.COMMON.CONFIRM'),
          role: 'confirm',
          handler: () => {
            alert.dismiss(true);
          },
        },
      ]
    });
    await alert.present();
    alert.onDidDismiss().then((res) => {
        this.handlerSource.next(res.data)
    })
  }
}