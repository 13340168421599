import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs';
import { Store } from '@ngrx/store';

import { environment } from 'src/environments/environment';
import { loadingSensorsSuccess, loadingSensors, loadingSensorsError } from 'src/app/state/actions/sensors.actions';
import { loadingCriticalityElements, loadingCriticalityElementsSuccess, loadingCriticalityElementsError } from 'src/app/state/actions/criticalityElements.actions';
import { loadingBeds, loadingBedsSuccess, loadingBedsError } from 'src/app/state/actions/beds.actions';
import { Sensor } from 'src/app/shared/models/sensor';

@Injectable({
  providedIn: 'any'
})
export class MonitoringService {

  private _http = inject(HttpClient)
  private store = inject(Store)

  constructor() { }

  public loadSensors(keyword?: String) {

    this.store.dispatch(loadingSensors())

    return this._http.get(
        `${environment.apiUrl}/customer-infrastructure/devices`
        // `${environment.apiUrl}/events/sensors-status?unitId=1&unitId=2`
      ).pipe(
      tap((val: any) => {
        //@TODO restore below code
        // this.store.dispatch(loadingSensorsSuccess({sensors: val.content}))
        this.store.dispatch(loadingSensorsSuccess({sensors: val.content.map((el: Sensor, idx: number) => ({
          ...el,
          floorId: idx %2 == 1 ?  1 : 2
        }))}))
      }),
      catchError(err => {
        this.store.dispatch(loadingSensorsError())
        throw 'error in source. Details: ' + err;
      })
    ).subscribe()

  }

  public loadMonitoringElements() {

    this.store.dispatch(loadingCriticalityElements())

    return this._http.get(
        `${environment.apiUrl}/ma/v2/monitoring/elements`
      ).pipe(
      tap((val: any) => {
        this.store.dispatch(loadingCriticalityElementsSuccess({elements: val.elements}))
      }),
      catchError(err => {
        this.store.dispatch(loadingCriticalityElementsError())
        throw 'error in source. Details: ' + err;
      })
    ).subscribe()

  }

  public loadOverviewBedsElements(keyword?: String) {

    this.store.dispatch(loadingBeds())

    return this._http.get(
        `${environment.apiUrl}/ma/v2/monitoring/real-time-overview`, {
          params: {
            type: "bed"
          }
        }
      ).pipe(
      tap((val: any) => {
        this.store.dispatch(loadingBedsSuccess({beds: val.elements}))
      }),
      catchError(err => {
        this.store.dispatch(loadingBedsError())
        throw 'error in source. Details: ' + err;
      })
    ).subscribe()

  }

}
