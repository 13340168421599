import { Component, OnInit, Input, inject } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-refresh-error-tool',
  templateUrl: './refresh-error-tool.component.html',
  styleUrls: ['./refresh-error-tool.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule]
})
export class RefreshErrorToolComponent  implements OnInit {

  @Input()
  hasError: boolean
  @Input()
  isLoading: boolean
  @Input()
  handleRefreshCallbackFn: any
  @Input()
  loadRetryFn: any

  constructor() {
    this.hasError = false
    this.isLoading = false
  }

  private loadingCtrl: LoadingController = inject(LoadingController)

  ngOnInit() {}

  handleRefresh(event: any) {
    event.target.complete()
    this.handleRefreshCallbackFn()
  }


}
