<ion-toolbar [ngClass]="'toolbar'">
  <!-- <ion-buttons slot="start">
    <ion-icon name="cube" size="small" class="icon-monitoring-mode"></ion-icon>
  </ion-buttons> -->
  <ion-title [ngClass]="'title'">Bed id -> {{this.bedId}}</ion-title>
  <!-- <ion-buttons slot="end">
    <ion-icon name="pin" size="small" class="icon-pin"></ion-icon>
  </ion-buttons> -->
</ion-toolbar>
<div [ngClass]="'component-body'">
  <div [ngClass]="'camera-container'">
    <img src="https://img.freepik.com/free-photo/people-colorful-thermal-scan-with-celsius-degree-temperature_23-2149170134.jpg?w=2000&t=st=1690811440~exp=1690812040~hmac=36dcda0145fd15136451354ffc6f27aa590588d23f4866bf5288d13fcf14af55" [ngClass]="'streaming-sensor-img'"/>
    <div [ngClass]="'camera-fab'">
      <ion-fab slot="fixed" vertical="bottom" horizontal="end">
        <ion-fab-button [ngClass]="'camera-fab-button'" color="light" (click)="onPressConfigureSensorCamFAB()">
          <ion-icon name="scan-sharp" [ngClass]="'camera-fab-icon'"></ion-icon>
        </ion-fab-button>
        <ion-fab-button [ngClass]="'camera-fab-button'" color="light" (click)="onPressPresetsFAB()">
          <ion-icon name="eye-sharp" [ngClass]="'camera-fab-icon'"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </div>
  </div>
  <div>
    <div [ngClass]="'manage-event-container'">
      <ion-label *ngIf="!this.toManageEvent" [ngClass]="'no-events-label'">Nessun evento da gestire</ion-label>
      <ion-button
        *ngIf="this.toManageEvent"
        (click)="onPressManageEventButton()"
        [ngClass]="'manage-event-button'"
      >GESTISCI EVENTO</ion-button>
    </div>
    <div [ngClass]="'select-camera-container'">
      <ion-label [ngClass]="'select-camera-label'">SELEZIONA VIDEOCAMERA</ion-label>
      <app-segmented-button
        [buttons]="this.segmentedButtons"
        [selectedButton]="this.selectedCamera"
        (handlePressButton)="onPressCameraButton($event)"
      ></app-segmented-button>
    </div>
  </div>
</div>
