import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonContent, IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { GenericService } from 'src/app/core/services/generic.service';
import { take, tap } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { IonicColors } from 'src/app/shared/enums/ionic-colors.enum';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-disclaimer-request',
  templateUrl: './disclaimer-request.page.html',
  styleUrls: ['./disclaimer-request.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, TranslateModule],
  providers: [GenericService]
})
export class DisclaimerRequestPage {
  public selectedSegmentIndex: number = 0;
  public disclaimersData: Array<IDisclaimerData> = [
    {
      id: 'cgs',
      label: 'GENERAL.DISCLAIMERS.CGS_SHORT',
      disabled: false,
      readed: false,
      accepted: false,
      text: ""
    },{
      id: 'cgu',
      label: 'GENERAL.DISCLAIMERS.CGU_SHORT',
      disabled: true,
      readed: false,
      accepted: false,
      text: ""
    },{
      id: 'privacy-policy',
      label: 'GENERAL.DISCLAIMERS.PRIVACY_POLICY_SHORT',
      disabled: true,
      readed: false,
      accepted: true,
      text: ""
    }
  ]

  constructor(
    private router: Router,
    private genericService: GenericService,
    private translateService: TranslateService,
    private userService: UserService,
    private toastService: ToastService,
    private authService: AuthService
  ) { }

  /**
   * @description ion-content contenente il testo del documento in oggetto
   */
  @ViewChild('disclaimerText') content!: IonContent;

  ionViewWillEnter() {
    // Per ogni documento da mostrare andiamo a recuperare il testo e ad inserirlo nel rispettivo oggetto al parametro "text"
    this.disclaimersData.forEach((disclaimer) => {
      this.genericService.getTextFile$(`assets/files/disclaimers/${this.translateService.currentLang || 'it'}/${disclaimer.id}.txt`)
        .pipe(
          take(1)
        ).subscribe({
          next: (response) => {
            response && (disclaimer.text = response.body);
          },
          error: () => {
            this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.FILENOTFOUND'), IonicColors.RED);
          }
        });
    })
  }

  /**
   * @description Invocata allo scroll del "ion-content", verifica se il documento è stato scrollato fino in fondo
   * @param selectedSegmentIndex Index del segmento selezionato, utilizzato per andare a cambiare il parametro del disclaimer rispettivo nel disclaimerData attraverso la funzione detectBottom
   */
  scrolling(selectedSegmentIndex: number) { // event has an interface but I don't need it
    this.detectBottom(selectedSegmentIndex);
  }

  /**
   * @description Sulla base della altezza del documento e dello scroll controlla se si è scrollato tutto il componente e in caso setta il parametro 'readed' a true e sblocca il segmento sucessivo
   * @param selectedSegmentIndex Index del segmento selezionato
   */
  async detectBottom(selectedSegmentIndex: number) {
    const scrollElement = await this.content.getScrollElement();
   // Calcolo per capire se è stato raggiunto il fondo del documento
    if (
      scrollElement.scrollTop ===
      scrollElement.scrollHeight - scrollElement.clientHeight
    ) {
      this.disclaimersData[selectedSegmentIndex].readed = true;
      selectedSegmentIndex !== 2 && (this.disclaimersData[selectedSegmentIndex+1].disabled = false);
    }
  }

  /**
   * @description Invocata al click della CTA di Prosegui
   */
  submitDisclaimer() {
    this.userService.editUserInfo$({
      serviceTermsAcceptance: true
    }).pipe(
      tap((res) => {
        console.log(res);
        this.router.navigate(['/wita/appMonitoring']);
      })
      ).subscribe()
    
  }

  /**
   * @description Invocata al click della CTA di Logout
   */
  logout() {
    this.authService.logout$().subscribe();
  }

  /**
   * @description Alla selezione di un segmento chiamo questa funzione che si occupa di scrollare in alto il documento
   */
  scrollDocumentToTop() {
    this.content.scrollToTop();
  }

  /**
   * @description Se ci sono informative che non sono state accettate o lette disabilito il pulsante per proseguire
   */
  get disableSubmit(): boolean {
    return this.disclaimersData.some((disclaimer) => !disclaimer.accepted || !disclaimer.readed)
  }
}

interface IDisclaimerData {
  id: 'cgs' | 'cgu' | 'privacy-policy';
  label: string;
  /**
   * @description Sancisce la disabilitazione del segmento
   */
  disabled: boolean;
  /**
   * @description Definisce se il documento è stato letto, per farlo viene controllato se è stato scrollato in fondo
   */
  readed: boolean;
  /**
   * @description Definisce l'accettazione del consenso del documento in questione
   */
  accepted: boolean;
  /**
   * @description Contiene il testo del documento in oggetto
   */
  text: string;
};
