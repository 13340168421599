<div [ngClass]="'segmented-buttons'">
  <div
    *ngFor="let button of buttons; let i = index"
    [ngClass]="'segmented-button-outer-container'"
  >
    <div [ngClass]="'camera-button-container'" (click)="onPressButton(button.id)">
      <div
        [ngClass]="['icon-segmented-outer', button.id === this.selectedButton ? 'icon-segmented-outer-selected' : '']"
        [ngStyle]="button.id !== this.selectedButton ? {'border-color': button.color} : (button.warnable ? {'border-color': button.color, 'background-color': button.color} : {})"
      >
        <ion-icon
          [name]="button.icon"
          [size]="'large'"
          [ngStyle]="button.id !== this.selectedButton ? {'color': button.color} : {}"
          [ngClass]="button.id === this.selectedButton ? 'camera-button-icon-selected' : ''"
        ></ion-icon>
      </div>
      <ion-label
        [ngClass]="['camera-button-label', button.id === this.selectedButton ? 'camera-button-label-selected' : '']"
      >{{button.title}}</ion-label>
    </div>
    <div *ngIf="button.separator" [ngClass]="'segmented-separator'"></div>
  </div>
</div>