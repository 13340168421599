import { createReducer, on } from '@ngrx/store';

import * as RoomsActions from '../actions/rooms.actions';
import { RoomsState } from 'src/app/shared/models/state/roomsState';

export const ROOMS_FEATURE_KEY = 'rooms';

export const initialRoomsState: RoomsState = {
  list: [],
  selectedRooms: []
};

export const roomsReducer = createReducer(
  initialRoomsState,
  on(RoomsActions.addSelectedRoom, (state, action) => {
    return({
      ...state,
      selectedRooms: [...state.selectedRooms, action.roomId]
    })
  }),
  on(RoomsActions.removeSelectedRoom, (state, action) => {
    return({
      ...state,
      selectedRooms: state.selectedRooms.filter((roomId: number) => roomId !== action.roomId)
    })
  }),
  on(RoomsActions.clearSelectedRooms, (state, action) => {
    return({
      ...state,
      selectedRooms: []
    })
  })
);
