<div [ngClass]="'overview-tab-body'">
  <div [ngClass]="'overview-toolbar-container'">
    <ion-segment
      [value]="this.overviewMode"
      color="dark"
      [ngClass]="'beds-rooms-segmented-button'"
      (ionChange)="this.overviewModeChange($event)"
    >
      <ion-segment-button value="beds">
        <ion-label>Letti</ion-label>
      </ion-segment-button>
      <ion-segment-button value="rooms">
        <ion-label>Stanze</ion-label>
      </ion-segment-button>
    </ion-segment>
    <ion-button
      (click)="enableSensorsSelection()"
      [color]="this.multiSelectionEnabled ? 'primary' : 'light'"
      size="small"
      [ngClass]="'select-deselect-button'"
    >{{this.multiSelectionEnabled ? "Annulla" : "Seleziona"}}</ion-button>
  </div>
  <app-monitoring-beds
    *ngIf="this.overviewMode === 'beds'"
    [multiSelectionEnabled]="this.multiSelectionEnabled"
    [ngClass]="'monitoring-list-component'"
  ></app-monitoring-beds>
  <app-monitoring-rooms
    *ngIf="this.overviewMode === 'rooms'"
    [multiSelectionEnabled]="this.multiSelectionEnabled"
    [ngClass]="['monitoring-list-component', 'monitoring-rooms']"
  ></app-monitoring-rooms>
</div>
<ion-footer *ngIf="this.multiSelectionEnabled">
  <ion-toolbar [color]="'dark'">
    <div [ngClass]="'multiselection-toolbar-container'">
      <ion-label [ngClass]="'multiselection-toolbar-selected-items-label'">
        {{this.selectedItems.length == 0 ?
            (this.overviewMode === "beds" ? "Seleziona letti" : "Seleziona stanze")
            : (this.selectedItems.length.toString() + (this.overviewMode === "beds" ? " letti selezionati" : " stanze selezionate"))}}
      </ion-label>
      <ion-icon *ngIf="this.selectedItems.length > 0" name="options-sharp" size="large" color="primary"></ion-icon>
    </div>
  </ion-toolbar>
</ion-footer>
