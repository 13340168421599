import * as fromUserReducer from "./reducers/user.reducer";
import * as fromSensorsReducer from "./reducers/sensors.reducer";
import * as fromBedsReducer from "./reducers/beds.reducer";
import * as fromRoomsReducer from "./reducers/rooms.reducer";
import * as fromOrganizationsReducer from "./reducers/organizations.reducer";
import * as fromUnitsReducer from "./reducers/units.reducer";
import * as fromCriticalityElementsReducer from "./reducers/criticalityElements.reducer";
import { UserState } from "../shared/models/state/userState";
import { Sensor } from "../shared/models/sensor";
import { BedsState } from "../shared/models/state/bedsState";
import { RoomsState } from "../shared/models/state/roomsState";
import { OrganizationsState } from "../shared/models/state/organizationsState";
import { UnitsState } from "../shared/models/state/unitsState";
import { CriticalityElementsState } from "../shared/models/state/criticalityElementsState";

export const storeWita = {
    [fromUserReducer.USER_FEATURE_KEY]: fromUserReducer.userReducer,
    [fromCriticalityElementsReducer.CRITICALITY_ELEMENTS_FEATURE_KEY]: fromCriticalityElementsReducer.criticalityElementsReducer,
    [fromSensorsReducer.SENSORS_FEATURE_KEY]: fromSensorsReducer.sensorsReducer,
    [fromBedsReducer.BEDS_FEATURE_KEY]: fromBedsReducer.bedsReducer,
    [fromRoomsReducer.ROOMS_FEATURE_KEY]: fromRoomsReducer.roomsReducer,
    [fromOrganizationsReducer.ORGANIZATIONS_FEATURE_KEY]: fromOrganizationsReducer.organizationsReducer,
    [fromUnitsReducer.UNITS_FEATURE_KEY]: fromUnitsReducer.unitsReducer,
}

export interface AppState {
    user: UserState,
    criticalityElements: CriticalityElementsState,
    sensors: Sensor[],
    beds: BedsState,
    rooms: RoomsState,
    organizations: OrganizationsState,
    units: UnitsState
}
