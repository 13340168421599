<app-main-top-toolbar></app-main-top-toolbar>
<ion-content [scrollY]="false">
  <ion-tabs>
    <ion-tab-bar slot="top" [ngClass]="'tab-bar'">
      <ion-tab-button tab="criticality">
        <ion-label>Criticità</ion-label>
      </ion-tab-button>
      <ion-tab-button tab="overview">
        <ion-label>Panoramica</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</ion-content>