import { Component, inject } from '@angular/core';
import { NavigationTopToolbarComponent } from 'src/app/shared/components/navigation-top-toolbar/navigation-top-toolbar.component';
import { Router } from '@angular/router';
import { SharedModule } from 'src/app/shared/modules/shared.module';

@Component({
  selector: 'app-app-settings',
  templateUrl: './app-settings.page.html',
  styleUrls: ['./app-settings.page.scss'],
  standalone: true,
  imports: [SharedModule, NavigationTopToolbarComponent]
})
export class AppSettingsPage {
  private router = inject(Router);
  constructor() { }

  ionViewWillEnter() { }

  navToSettingsPage(pageUrl:string){
    this.router.navigate([`/wita/appMonitoring/monitoringRoot/settings/${pageUrl}`]);
  }
}
