import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError, Observable, map } from 'rxjs';
import { Store } from '@ngrx/store';

import { environment } from 'src/environments/environment';
import { loadingUser, loadUserSuccess, loadingUserError } from 'src/app/state/actions/user.actions';
import { LoggerService } from './logger.service';
import { DeviceSpecs, LocalUserDevices, UserDevices } from 'src/app/shared/models/user-devices';

@Injectable({
  providedIn: 'any'
})
export class UserService {

  private _http = inject(HttpClient)
  private store = inject(Store)

  constructor(
    private loggerService: LoggerService
  ) { }

  public loadUserInfos() {
    this.store.dispatch(loadingUser());
    return this._http.get(`${environment.apiUrl}/user-info/details`).pipe(
      tap((val: any) => {
        this.store.dispatch(loadUserSuccess({user: val}))
      }),
      catchError(err => {
        this.store.dispatch(loadingUserError())
        this.loggerService.error('Error on .../user-info/details')
        throw 'error in source. Details: ' + err;
      })
    ).subscribe();
  }

  /**
   * @returns Torna i devices collegati allo user corrente in formato LocalUserDevices
   */
  getUserDevices$(): Observable<Array<LocalUserDevices>> {
    return this._http.get<Array<UserDevices>>(`${environment.apiUrl}/user-info/user-devices`).pipe(
      map((response) => (response || []).map((device) => {
        const deviceInfo: DeviceSpecs = JSON.parse(device.deviceName) || {}
        return {
          lastTimeOnline: new Date(device.lastUpdateDate).toLocaleDateString(),
          os: ['windows','android'].includes(deviceInfo.os.toLowerCase()) ? deviceInfo.os.toLowerCase() : 'generic',
          id: device.id,
          deviceId: device.deviceId,
          token: device.deviceToken
        } as LocalUserDevices
      }))
    );
  }

  public editUserInfo$(body: {[key: string]: string | boolean}) {
    return this._http.patch(`${environment.apiUrl}/user-info/details`, body).pipe(
      tap((val: any) => {
        this.store.dispatch(loadUserSuccess({user: val}));
      }),
      catchError(err => {
        this.store.dispatch(loadingUserError());
        this.loggerService.error('Error on .../user-info/details');
        throw 'error in source. Details: ' + err;
      })
    )
  }
}
