<div>
  <div [ngClass]="'loading-container'" *ngIf="this.isLoading">
    <ion-spinner color="primary"></ion-spinner>
    <ion-label [ngClass]="'loading-label'">Caricamento...</ion-label>
  </div>
  <div *ngIf="this.hasError" [ngClass]="'retry-error-tools-container'">
    <ion-label color="danger" [ngClass]="'error-label'">{{this.errorText || "Errore caricamento..."}}</ion-label>
    <ion-button (click)="loadRetryFn()" fill="outline" size="small">
      <ion-icon slot="start" name="refresh-outline"></ion-icon>
      Riprova
    </ion-button>
  </div>
</div>