<app-navigation-top-toolbar
  [title]="'Notifiche'"
></app-navigation-top-toolbar>
<ion-content color="light">
  <div [ngClass]="'settings-items-container'">
    <ion-label [ngClass]="'settings-grouping-title'">ALERT EVENTO</ion-label>
    <ion-list [inset]="true" [ngClass]="'settings-list-items'">
      <ion-item
        *ngFor="let eventAlert of this.eventsAlertTypesList; let i = index;"
        button
        [detail]="false"
        (click)="onEventAlertPress(eventAlert.value)"
      >
        <ion-icon [name]="eventAlert.icon" [ngClass]="'icon-item-type'" slot="start"></ion-icon>
        <ion-label>{{eventAlert.text}}</ion-label>
        <ion-icon *ngIf="this.eventsAlertValue === eventAlert.value" name="checkmark-outline" slot="end" color="primary"></ion-icon>
      </ion-item>
    </ion-list>
    <ion-label [ngClass]="'settings-grouping-title'">CONNESSIONE PERSA</ion-label>
    <ion-list [inset]="true" [ngClass]="'settings-list-items'">
      <ion-item
        *ngFor="let eventAlert of this.eventsAlertTypesList; let i = index;"
        button
        [detail]="false"
        (click)="onLostConnectionAlertPress(eventAlert.value)"
      >
        <ion-icon [name]="eventAlert.icon" [ngClass]="'icon-item-type'" slot="start"></ion-icon>
        <ion-label>{{eventAlert.text}}</ion-label>
        <ion-icon *ngIf="this.lostConnectionAlertValue === eventAlert.value" name="checkmark-outline" slot="end" color="primary"></ion-icon>
      </ion-item>
    </ion-list>
  </div>
</ion-content>