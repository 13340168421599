import { Component, OnInit, Input } from '@angular/core';
import { IonicModule, MenuController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { User } from '../../models/user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule]
})
export class UserInfoComponent  implements OnInit {
  
  @Input()
  data: User;

  email: string;
  firstLastName: string;

  constructor(
    private router: Router,
    private menuController: MenuController
  ) { 
    this.data = {
      firstname: "",
      lastname: "",
      email: "",
      userId: -1,
      organizationId: -1,
      username: "",
      role: "",
      multiTenant: false,
      serviceTermsAcceptance: false
    }
    this.email = ""
    this.firstLastName = ""
  }

  ngOnInit() {
    this.email = this.data.email
    this.firstLastName = this.data.firstname + " " + this.data.lastname
  }

  /**
   * @description Funzione invocata al click dell'immagine profilo nel menu, chiude il menu e reinderizza alla pagina account dei settings
   */
  goToAccount() {
    this.menuController.close();
    this.router.navigate([`/wita/appMonitoring/monitoringRoot/settings/account`]);
  }

}