import { createAction, props } from '@ngrx/store';
import { Organization } from 'src/app/shared/models/organization';

export const initOrganizations = createAction('[WITA] Init Organizations');

export const loadingOrganizations = createAction(
  '[WITA] Loading Organizations'
);
export const loadOrganizationsSuccess = createAction(
  '[WITA] Load Organizations Success',
  props<{ organizations: Organization[] }>()
);
export const loadingOrganizationsError = createAction(
  '[WITA] Loading Organizations Error'
);
