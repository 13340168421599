import { Component } from '@angular/core';

import { NavigationTopToolbarComponent } from 'src/app/shared/components/navigation-top-toolbar/navigation-top-toolbar.component';
import { ListOptionsElement } from 'src/app/shared/models/list-options-element';
import { SharedModule } from 'src/app/shared/modules/shared.module';

@Component({
  selector: 'app-notifications-settings',
  templateUrl: './notifications-settings.page.html',
  styleUrls: ['./notifications-settings.page.scss'],
  standalone: true,
  imports: [SharedModule, NavigationTopToolbarComponent]
})
export class NotificationsSettingsPage {

  eventsAlertTypesList: ListOptionsElement[];
  eventsAlertValue: string;
  lostConnectionAlertValue: string;

  constructor() {
    this.eventsAlertTypesList = [
      {
        value: "mute",
        icon: "volume-mute-outline",
        text: "Nessuna notifica"
      },
      {
        value: "sound",
        icon: "volume-high-outline",
        text: "Sonoro"
      },
      {
        value: "vibration",
        icon: "pulse-outline",
        text: "Vibrazione"
      },
      {
        value: "read",
        icon: "chatbox-ellipses-outline",
        text: "Lettura evento"
      }
    ];
    this.eventsAlertValue = "";
    this.lostConnectionAlertValue = "";

  }

  ionViewWillEnter() {}

  ionViewWillLeave() {}

  onEventAlertPress(value: string) {
    this.eventsAlertValue = value;
  }

  onLostConnectionAlertPress(value: string) {
    this.lostConnectionAlertValue = value;
  }

}
