<app-navigation-top-toolbar
  [title]="'GENERAL.DISCLAIMERS.TERMS_AND_PRIVACY' | translate"
></app-navigation-top-toolbar>
<ion-content color="light">
  <div style="display: flex;flex-direction: column;justify-content: center;text-align: center;margin:20px;">
      <ion-list style="border-radius: 6px;">
        <ion-item button detail="true">
          <ion-label (click)="openDoc('privacy-policy')">{{'GENERAL.DISCLAIMERS.PRIVACY_POLICY' | translate}}</ion-label>
        </ion-item>
        <ion-item button detail="true">
          <ion-label (click)="openDoc('cgu')">{{'GENERAL.DISCLAIMERS.CGU' | translate}}</ion-label>
        </ion-item>
        <ion-item button detail="true">
          <ion-label (click)="openDoc('cgs')">{{'GENERAL.DISCLAIMERS.CGS' | translate}}</ion-label>
        </ion-item>
      </ion-list>
  </div>
  <ion-modal [isOpen]="isModalOpen">
    <ng-template>
      <ion-header>
        <ion-toolbar>
          <ion-title>{{modalTitle | translate}}</ion-title>
          <ion-buttons slot="end">
            <ion-button (click)="openDoc(false)">{{'GENERAL.COMMON.CLOSE' | translate}}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <div class="cont"><pre class="pre_txt">{{modalText}}</pre></div>
      </ion-content>
    </ng-template>
  </ion-modal>
</ion-content>