<ion-searchbar
  [debounce]="2000"
  [ngClass]="'beds-searchbar'"
  (ionInput)="handleFilterTiles($event)"
></ion-searchbar>
<ion-content color="light">
  <app-refresh-error-tool
    *ngIf="!this.multiSelectionEnabled"
    [hasError]="this.hasError"
    [isLoading]="this.loadingBeds"
    [handleRefreshCallbackFn]="this.loadBeds.bind(this)"
    [loadRetryFn]="this.loadBeds.bind(this)"
  ></app-refresh-error-tool>
  <div
      *ngFor="let room of rooms; let i = index"
  >
    <div [ngClass]="'grouped-tiles-header'">
      <ion-label [ngClass]="'unit-room-name-label'">{{room.name}}</ion-label>
    </div>
    <div
      [ngClass]="'grouped-tiles-container'"
    >
      <app-monitoring-tile
        *ngFor="let bed of room.beds; let i = index"
        [tileData]="bed"
        [tilesSelectionMode]="this.multiSelectionEnabled"
        [showBodyInfos]="true"
        [isSelected]="getSelected(bed.id)"
        (handlePressTile)="handlePressBed($event)"
        (handleSelectTile)="handleSelectBed($event)"
        [ngClass]="'bed-tile'"
      ></app-monitoring-tile>
    </div>
  </div>
</ion-content>
