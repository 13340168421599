import { Component, OnInit, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { switchMap, of, Subject, takeUntil } from 'rxjs';
import { MenuController } from '@ionic/angular';

import { SideMenuActionButton } from '../../models/side-menu-action-button';
import { ActionButtonComponent } from '../action-button/action-button.component';
import { UserInfoComponent } from '../user-info/user-info.component';
import { RetryErrorToolSmallComponent } from '../retry-error-tool-small/retry-error-tool-small.component';
import { User } from '../../models/user';
import * as fromUserSelectors from "../../../state/selectors/user.selectors"
import { UserService } from 'src/app/core/services/user.service';
import { UserState } from '../../models/state/userState';
import { SharedModule } from '../../modules/shared.module';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  standalone: true,
  imports: [SharedModule, ActionButtonComponent, UserInfoComponent, RetryErrorToolSmallComponent]
})
export class SideMenuComponent  implements OnInit {

  actionButtons: Array<SideMenuActionButton>;
  userData: User | null;
  isLoading: boolean;
  hasError: boolean;

  private menuController: MenuController = inject(MenuController)
  private userService = inject(UserService)
  private store = inject(Store)

  constructor() { 
    this.actionButtons = [
      /* {
        icon: "person-outline",
        label: "Gestione dell'account",
        path: ""
      },
      {
        icon: "notifications-outline",
        label: "Notifiche",
        path: "/wita/appMonitoring/monitoringRoot/settings/notifications"
      },
      {
        icon: "business-outline",
        label: "Strutture monitorate",
        path: "/wita/appMonitoring/monitoringRoot/settings/infrastructure"
      }, */
      {
        icon: "settings-outline",
        label: "PAGES.SETTINGS.TITLE",
        path: "/wita/appMonitoring/monitoringRoot/settings"
      },
      {
        icon: "help-circle-outline",
        label: "GENERAL.DISCLAIMERS.TERMS_AND_PRIVACY",
        path: "/wita/appMonitoring/monitoringRoot/settings/terms-and-privacy"
      }
    ]

    this.userData = null
    this.isLoading = false
    this.hasError = false
  }

  private destroyed$ = new Subject()

  ngOnInit() {
    this.store.pipe(
      takeUntil(this.destroyed$),
      select(fromUserSelectors.selectUser),
      switchMap((state: UserState) => {
        let {
          data,
          isLoading,
          hasError
        } = state
        this.userData = data
        this.isLoading = isLoading
        this.hasError = hasError

        return of(state)
      })
    ).subscribe()

    this.loadUserInfos()

  }

  ngOnDestroy() {
    this.destroyed$.next(1)
    this.destroyed$.complete()
  }

  loadUserInfos() {
    this.userService.loadUserInfos()
  }

  actionButtonCallbackFn() {
    this.menuController.close()
  }

}