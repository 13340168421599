import { createReducer, on } from '@ngrx/store';

import * as SensorsActions from '../actions/sensors.actions';
import { Sensor } from 'src/app/shared/models/sensor';

export const SENSORS_FEATURE_KEY = 'sensors';

export const initialSensorsState: Sensor[] = [];

export const sensorsReducer = createReducer(
  initialSensorsState,
  on(SensorsActions.loadingSensors, state => {
    return ({
      ...state,
      list: [],
      isLoading: true,
      hasError: false
    })
  }),
  on(SensorsActions.loadingSensorsSuccess, (state, action) => {
    return({
      ...state,
      list: action.sensors,
      isLoading: false,
      hasError: false
    })
  }),
  on(SensorsActions.loadingSensorsError, state => {
    return ({
      ...state,
      list: [],
      isLoading: false,
      hasError: true
    })
  }),
);
