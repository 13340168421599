import { Injectable, inject } from "@angular/core";
import { Location } from "@angular/common";
import { Router, NavigationEnd } from "@angular/router";
 
@Injectable({
  providedIn: "root"
})
export class NavigationService {
  
  private history: string[] = []
  private router = inject(Router)
  private location = inject(Location)
 
  constructor() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.history.length > 0) {
          /**
           * Tabs controls.
           * Avoid multiple equals route in the history stack
           */
          if (event.urlAfterRedirects !== this.history[this.history.length -1]) {
            this.history.push(event.urlAfterRedirects);
          }
        } else {
          this.history.push(event.urlAfterRedirects);
        }
        console.log(this.history)
      }
    });
  }
 
  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl("/wita/appMonitoring");
    }
  }
}
