import { createAction, props } from '@ngrx/store';

import { Sensor } from 'src/app/shared/models/sensor';

export const initSensors = createAction('[WITA_SENSORS] Init sensors');
export const loadingSensors = createAction(
  '[WITA_SENSORS] Loading Sensors'
);
export const loadingSensorsSuccess = createAction(
  '[WITA_SENSORS] Load Sensors Success',
  props<{ sensors: Sensor[] }>()
);
export const loadingSensorsError = createAction(
  '[WITA_SENSORS] Loading Sensors Error'
);
