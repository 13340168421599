import { Routes } from '@angular/router';

import { MonitoringRootPage } from './monitoring-root.page';
import { MonitoringTabNavPage } from '../monitoring-tab-nav/monitoring-tab-nav.page';
import { CriticalityTabPage } from '../criticality-tab/criticality-tab.page';
import { OverviewTabPage } from '../overview-tab/overview-tab.page';
import { SensorsStackNavPage } from '../../sensors/sensors-stack-nav/sensors-stack-nav.page';
import { BedDetailPage } from '../../sensors/bed-detail/bed-detail.page';
import { NotificationsSettingsPage } from '../../settings/notifications-settings/notifications-settings.page';
import { InfrastructureSettingsPage } from '../../settings/infrastructure-settings/infrastructure-settings.page';
import { InfrastructureSelectPage } from '../../settings/infrastructure-select/infrastructure-select.page';
import { TermsAndPrivacyPage } from '../../terms-and-privacy/terms-and-privacy.page';
import { AccountSettingsPage } from '../../settings/account-settings/account-settings.page';
import { AppSettingsPage } from '../../settings/app-settings/app-settings.page';

export const MonitoringRoutes: Routes = [
  {
    path: 'monitoringRoot',
    component: MonitoringRootPage,
    children: [
      {
        path: 'monitoringTabs',
        component: MonitoringTabNavPage,
        children: [
          {
            path: 'criticality',
            component: CriticalityTabPage
          },
          {
            path: 'overview',
            component: OverviewTabPage
          },
          {
            path: '',
            redirectTo: 'criticality',
            pathMatch: 'full',
          }
        ]
      },
      {
        path: 'sensors',
        component: SensorsStackNavPage,
        children: [
          {
            path: 'bed',
            component: BedDetailPage
          },
          {
            path: 'room',
            component: BedDetailPage
          },
          {
            path: '',
            redirectTo: 'bed',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'settings',
        children: [
          {
            path: 'appsettings',
            component: AppSettingsPage
          },
          {
            path: 'account',
            component: AccountSettingsPage
          },
          {
            path: 'notifications',
            component: NotificationsSettingsPage
          },
          {
            path: 'infrastructure',
            component: InfrastructureSettingsPage
          },
          {
            path: 'infrastructureSelect',
            component: InfrastructureSelectPage
          },
          {
            path: 'terms-and-privacy',
            component: TermsAndPrivacyPage
          },
          {
            path: '',
            redirectTo: 'appsettings',
            pathMatch: 'full',
          }
        ]
      },
      {
        path: '',
        redirectTo: 'monitoringTabs',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    redirectTo: 'monitoringRoot',
    pathMatch: 'full',
  }
];
