import { createAction, props } from '@ngrx/store';

import { MonitoringElement } from 'src/app/shared/models/monitoringElement';

export const initCriticalityElements = createAction('[CRITICALITY_PAGE] Init elements');
export const loadingCriticalityElements = createAction(
  '[CRITICALITY_PAGE] Loading elements'
);
export const loadingCriticalityElementsSuccess = createAction(
  '[CRITICALITY_PAGE] Load elements Success',
  props<{ elements: MonitoringElement[] }>()
);
export const loadingCriticalityElementsError = createAction(
  '[CRITICALITY_PAGE] Loading elements Error'
);
