<ion-content [fullscreen]="true">
  <div ngClass="login-outer-container">
    <div ngClass="login-logo-container">
      <ion-icon name="logo-mastodon" aria-hidden="true" ngClass="login-logo"></ion-icon>
    </div>
    <div ngClass="login-form">
      <div>
        <!-- HEADER -->
        <div ngClass="login-form_header">
          <ion-label>
            <h1>
              {{'PAGES.LOGIN.RESET_PW_TITLE' | translate}}
            </h1>
          </ion-label>
          <ion-label>
            <h3>
              {{'PAGES.LOGIN.RESET_PW_PAGE_SUBTITLE' | translate}}
            </h3>
          </ion-label>
        </div>
          <!-- RESET PW FORM -->
          <form [formGroup]="resetPwForm" ngClass="login-form_inputs">
            <div [ngClass]="'field-container'">
              <ion-input
                [label]="'PAGES.LOGIN.FORM.PASSWORD.LABEL' | translate"
                labelPlacement="stacked"
                [placeholder]="'PAGES.LOGIN.FORM.PASSWORD.PLACEHOLDER' | translate"
                [type]="showPassword ? 'text' : 'password'"
                formControlName="password"
                class="login-field"
                [errorText]="'GENERAL.FORM.VALIDATOR_ERRORS.INVALID_PASSWORD' | translate"
                [helperText]="!resetPwFormControls['password'].value ? ('GENERAL.FORM.HELPERS.INSERT_PASSWORD' | translate) : ''"
                color="primary"
              >
                <ion-icon name="lock-closed-outline" aria-hidden="true" ngClass="field-icon" slot="start"></ion-icon>
                <ion-button fill="clear" slot="end" aria-label="Show/hide" (click)="showPassword = !showPassword">
                  <ion-icon slot="icon-only" [name]="showPassword ? 'eye-off-outline' : 'eye-outline'" aria-hidden="true"></ion-icon>
                </ion-button>
              </ion-input>
            </div>
            <div [ngClass]="'field-container'">
              <ion-input
                [label]="'PAGES.LOGIN.FORM.CONFIRM_PASSWORD.LABEL' | translate"
                labelPlacement="stacked"
                [placeholder]="'PAGES.LOGIN.FORM.CONFIRM_PASSWORD.PLACEHOLDER' | translate"
                [type]="showConfirmPassword ? 'text' : 'password'"
                formControlName="confirmPassword"
                class="login-field"
                [errorText]="'GENERAL.FORM.VALIDATOR_ERRORS.PWS_NOT_MATCHING' | translate"
                color="primary"
              >
                <ion-icon name="lock-closed-outline" aria-hidden="true" ngClass="field-icon" slot="start"></ion-icon>
                <ion-button fill="clear" slot="end" aria-label="Show/hide" (click)="showConfirmPassword = !showConfirmPassword">
                  <ion-icon slot="icon-only" [name]="showConfirmPassword ? 'eye-off-outline' : 'eye-outline'" aria-hidden="true"></ion-icon>
                </ion-button>
              </ion-input>
            </div>
          </form>
      </div>
      <!-- CTA -->
      <div ngClass="login-form_multiple-buttons">
        <ion-button (click)="goToLogin()" color="medium">
          {{'GENERAL.COMMON.GO_BACK_TO_LOGIN' | translate}}
        </ion-button>
        <ion-button (click)="submitPassword()">
          {{'GENERAL.COMMON.SAVE' | translate}}
        </ion-button>
      </div>
      <!-- COPYRIGHT -->
      <div ngClass="login-form_copyright">
        <p>{{'GENERAL.COPYRIGHT' | translate}}</p>
      </div>
    </div>
  </div>
</ion-content>
