import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SENSORS_FEATURE_KEY } from '../reducers/sensors.reducer';
import { Sensor } from 'src/app/shared/models/sensor';

export const selectSensorsState = createFeatureSelector<Sensor>(SENSORS_FEATURE_KEY);

export const selectSensors = createSelector(
  selectSensorsState,
  (state: Sensor) => state
);
