import { createReducer, on } from '@ngrx/store';

import * as BedsActions from '../actions/beds.actions';
import { BedsState } from 'src/app/shared/models/state/bedsState';

export const BEDS_FEATURE_KEY = 'beds';

export const initialBedsState: BedsState = {
  list: [],
  selectedBeds: [],
  isLoading: false,
  hasError: false
};

export const bedsReducer = createReducer(
  initialBedsState,
  on(BedsActions.loadingBeds, state => {
    return ({
      ...state,
      list: [],
      isLoading: true,
      hasError: false
    })
  }),
  on(BedsActions.loadingBedsSuccess, (state, action) => {
    return({
      ...state,
      list: action.beds,
      isLoading: false,
      hasError: false
    })
  }),
  on(BedsActions.loadingBedsError, state => {
    return ({
      ...state,
      list: [],
      isLoading: false,
      hasError: true
    })
  }),
  on(BedsActions.addSelectedBed, (state, action) => {
    return({
      ...state,
      selectedBeds: [...state.selectedBeds, action.bedId]
    })
  }),
  on(BedsActions.removeSelectedBed, (state, action) => {
    return({
      ...state,
      selectedBeds: state.selectedBeds.filter((bedId: string) => bedId !== action.bedId)
    })
  }),
  on(BedsActions.clearSelectedBeds, (state, action) => {
    return({
      ...state,
      selectedBeds: []
    })
  })
);
