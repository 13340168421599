import { createReducer, on } from '@ngrx/store';

import * as OrganizationsActions from '../actions/organizations.actions';
import { Organization } from 'src/app/shared/models/organization';

export const ORGANIZATIONS_FEATURE_KEY = 'organizations';

export const initialOrganizationsState: Organization[] = [];

export const organizationsReducer = createReducer(
  initialOrganizationsState,
  on(OrganizationsActions.loadingOrganizations, state => {
    return ({
      ...state,
      data: [],
      isLoading: true,
      hasError: false
    })
  }),
  on(OrganizationsActions.loadOrganizationsSuccess, (state, action) => {
    return({
      ...state,
      data: action.organizations,
      isLoading: false,
      hasError: false
    })
  }),
  on(OrganizationsActions.loadingOrganizationsError, state => {
    return ({
      ...state,
      data: [],
      isLoading: false,
      hasError: true
    })
  }),
);
