import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

import { MonitoringTile } from '../../models/monitoringTile';

@Component({
  selector: 'app-monitoring-tile',
  templateUrl: './monitoring-tile.component.html',
  styleUrls: ['./monitoring-tile.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule]
})
export class MonitoringTileComponent  implements OnInit {

  @Input()
  tileData: MonitoringTile
  @Input()
  tilesSelectionMode: boolean
  @Input()
  enableVisualMonitoring: boolean
  @Input()
  isSelected: boolean
  @Input()
  showBodyInfos: boolean

  @Output()
  handlePressTile: EventEmitter<any> = new EventEmitter();
  @Output()
  handleSelectTile: EventEmitter<any> = new EventEmitter();

  constructor() {
    this.tileData = {
      id: "",
      isOnline: false,
      label: "",
      visitorName: "",
      unit: "",
      room: "",
      eventTime: "",
      pinned: false,
      riskStatusLevel: null,
      riskStatusLevelIcon: ""
    }

    this.tilesSelectionMode = false
    this.isSelected = false
    this.enableVisualMonitoring = false
    this.showBodyInfos = false
  }

  ngOnInit() {}

  onPressTile(event: any) {
    if(event.target.className.includes("pin-component")) {
      event.stopPropagation();
      return;
    }

    this.handlePressTile.emit(this.tileData.id)
  }

  clickPin(event: any) {
    console.log("click pin")
  }

  checkmarkElement() {
    this.handleSelectTile.emit(this.tileData.id)
  }

}
