import { createSelector } from '@ngrx/store';
import { AppState } from '../wita.store';
import { Organization } from 'src/app/shared/models/organization';
import { User } from 'src/app/shared/models/user';
import { GeneralPageState } from 'src/app/shared/models/state/generalPageState';

const selectUser = (state: AppState) => state.user.data
const selectOrganizations = (state: AppState) => state.organizations.data
export const selectSelectedUserOrganizationDetails = createSelector(
  selectUser,
  selectOrganizations,
  (selectedUser: User | null, allOrganizations: Organization[] | null) => {
    let userOrganizationId = selectedUser?.organizationId
    if (userOrganizationId && allOrganizations) {
      return allOrganizations.filter(organization => organization.id === userOrganizationId)
    } else return []
  }
)

//@TODO potenziale UT

const selectUserStatus = (state: AppState): GeneralPageState => ({isLoading: state.user.isLoading, hasError: state.user.hasError})
const selectOrganizationsStatus = (state: AppState): GeneralPageState => ({isLoading: state.organizations.isLoading, hasError: state.organizations.hasError})
const selectUnitsStatus = (state: AppState): GeneralPageState => ({isLoading: state.units.isLoading, hasError: state.units.hasError})
export const pageInfrastructureSettingsStatus = createSelector(
  selectUserStatus,
  selectOrganizationsStatus,
  selectUnitsStatus,
  (
    selectedUserState: any,
    selectedOrganizationsStatus: any,
    selectedUnitsStatus: any
  ): GeneralPageState => ({
    isLoading: selectedUserState.isLoading || selectedOrganizationsStatus.isLoading || selectedUnitsStatus.isLoading,
    hasError: selectedUserState.hasError || selectedOrganizationsStatus.hasError || selectedUnitsStatus.hasError
  })
)
