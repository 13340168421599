import { Component, OnInit, inject, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { switchMap, of, Subject, takeUntil, tap } from 'rxjs';
import { Router } from '@angular/router';

import { MonitoringService } from 'src/app/core/services/monitoring.service';
import { MonitoringElement } from 'src/app/shared/models/monitoringElement';
import { selectBeds, selectSelectedBeds } from 'src/app/state/selectors/beds.selectors';
import { BedsState } from 'src/app/shared/models/state/bedsState';
import { GroupingRoom } from 'src/app/shared/models/groupingRoom';
import {
  addSelectedBed,
  removeSelectedBed,
  clearSelectedBeds,
} from 'src/app/state/actions/beds.actions';
import { MonitoringTileComponent } from 'src/app/shared/components/monitoring-tile/monitoring-tile.component';
import { RefreshErrorToolComponent } from 'src/app/shared/components/refresh-error-tool/refresh-error-tool.component';
import { RiskStatusLevelIcons } from 'src/app/shared/enums/risk-status-level-icons';
import { MonitoringTile } from 'src/app/shared/models/monitoringTile';
import { Unit } from 'src/app/shared/models/unit';
import { Room } from 'src/app/shared/models/room';

@Component({
  selector: 'app-monitoring-beds',
  templateUrl: './beds.component.html',
  styleUrls: ['./beds.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, MonitoringTileComponent, RefreshErrorToolComponent]
})
export class BedsComponent implements OnInit {

  private monitoringService: MonitoringService = inject(MonitoringService)

  @Input()
  multiSelectionEnabled: boolean

  constructor() {
    this.beds = []
    this.loadingBeds = false
    this.hasError = false

    this.multiSelectionEnabled = false
    this.selectedBeds = []
    this.rooms = []
  }

  private store = inject(Store)
  private router = inject(Router)
  beds: MonitoringTile[]
  loadingBeds: boolean
  hasError: boolean
  selectedBeds: Array<string>
  rooms: Array<GroupingRoom>

  private destroyed$ = new Subject()

  ngOnInit() {

    //connect to beds state
    this.store.pipe(
      takeUntil(this.destroyed$),
      select(selectBeds),
      tap(val => console.log("selectBeds", val)),
      switchMap((val: Omit<BedsState, "selectedBeds">) => {
        this.rooms = this.groupBedsByRooms(val.list)
        this.loadingBeds = val.isLoading
        this.hasError = val.hasError || false
        return of(val)
      })
    ).subscribe()

    //connect to SELECTED beds state
    this.store.pipe(
      takeUntil(this.destroyed$),
      select(selectSelectedBeds),
      tap(val => console.log("SELECTEDbeds", val)),
      switchMap((val: Pick<BedsState, "selectedBeds">) => {
        this.selectedBeds = val.selectedBeds
        return of(val)
      })
    ).subscribe()

    this.loadBeds()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['multiSelectionEnabled']) {
      let {
        previousValue,
        currentValue
      } = changes['multiSelectionEnabled']

      if ((previousValue !== currentValue) && currentValue === false) {
        //clear multi selection case
        this.store.dispatch(clearSelectedBeds())
      }
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(1)
    this.destroyed$.complete()
  }

  loadBeds(keyword?: String) {
    this.monitoringService.loadOverviewBedsElements(keyword)
  }

  handlePressBed(event: any) {
    this.router.navigate(['/wita/appMonitoring/monitoringRoot/sensors/bed', { id: event }])
  }

  handleSelectBed(event: string) {
    if (this.getSelected(event)) {
      this.store.dispatch(removeSelectedBed({bedId: event}))
    } else {
      this.store.dispatch(addSelectedBed({bedId: event}))
    }
  }

  getSelected(bed: string) {
    return this.selectedBeds.filter(element => element === bed).length > 0
  }

  groupBedsByRooms(beds: Array<MonitoringElement>): Array<GroupingRoom> {
console.log("NON ENTRARE QUA!")
    const bedsWithValidsUnitAndRoom = beds.filter( bed => this.isValidsUnitAndRoom(bed.unit || ("" as any), bed.room || ("" as any)) )
    const distinctRooms = [...new Set(
      bedsWithValidsUnitAndRoom.map(validBed => `${validBed.room.id}`)
    )]
    let rooms: Array<GroupingRoom> = []
    
    /** Group beds by room (with both valid unit and room) */
    rooms = distinctRooms.map(roomId => {
      let bedMatchingRoom = bedsWithValidsUnitAndRoom.find(bed => (bed.room && (bed.room.id === parseInt(roomId))))
      let unitRoom = {
        unitId: bedMatchingRoom?.unit.id,
        unitName: bedMatchingRoom?.unit.name,
        roomId: bedMatchingRoom?.room.id,
        roomName: bedMatchingRoom?.room.name
      }

      return {
        id: roomId,
        name: `${unitRoom.unitName} - ${unitRoom.roomName}`,
        beds: bedsWithValidsUnitAndRoom
                .filter(bed => bed.room.id === unitRoom.roomId)
                .map((element: MonitoringElement) => this.mapBedToMonitoringTile(element))
      }
    })

    /** Group beds with NOT valid unit or/and room */
    if (beds.length > bedsWithValidsUnitAndRoom.length) {
      rooms = [
        ...rooms,
        {
          id: "999",
          name: "Unità o stanza mancante",
          beds: beds
                  .filter((bed: any) => !this.isValidsUnitAndRoom(bed.unit || ("" as any), bed.room ||  ("" as any)))
                  .map((element: MonitoringElement) => this.mapBedToMonitoringTile(element))
        }
      ]
    }

    return rooms
  }

  isValidsUnitAndRoom(unit: Unit, room: Room): boolean {
    let validUnit = false,
        validRoom = false

    if (typeof unit.name == "string" && typeof unit.id == "number") {
      validUnit = true
    }
    if (typeof room.name == "string" && typeof room.id == "number") {
      validRoom = true
    }

    return validUnit && validRoom
  }

  mapBedToMonitoringTile(bed: MonitoringElement): MonitoringTile {
    return ({
      id: bed.elementId,
      label: bed.name,
      isOnline: false, //@TODO dove recuperare il dato?
      visitorName: bed.person?.name || "",
      unit: bed.unit ? (bed.unit.name || "") : "",
      room: bed.room ? (bed.room.name || "") : "",
      eventTime: "", //@TODO dove recuperare il dato?
      pinned: true, //@TODO dove recuperare il dato?
      riskStatusLevel: bed.riskStatusLevel,
      riskStatusLevelIcon: bed.riskStatusLevel ? RiskStatusLevelIcons[bed.riskStatusLevel as keyof typeof RiskStatusLevelIcons] : ""
    })
  }

  handleFilterTiles(event: any) {
    let keyword = event.detail.value
    if (typeof keyword === "string" && keyword.trim() !== "") {
      this.loadBeds(keyword)
    } else {
      this.loadBeds()
    }
  }

}
