import { HttpClient } from "@angular/common/http"
import { Injectable, inject } from "@angular/core"
import { ToastController } from "@ionic/angular";
import { Store } from "@ngrx/store"
import { Observable } from "rxjs";

@Injectable()
/**
 *  @description Servizio di utility in app
 * */  
export class GenericService {  

    private _http = inject(HttpClient);
    private store = inject(Store);

    constructor(
        public toastController: ToastController
    ) { }

    /**
     * 
     * @param path string with relative path to the text file 
     * @returns 
     */
    getTextFile$(path: string): Observable<any> {
        const headers = {
            'Content-Type': 'text/html',
        };
        return this._http.get(`./${path}`, { headers: headers, observe: 'response', responseType: 'text' });
    }

}
  