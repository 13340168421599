import { Component, OnInit, Input, inject, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { switchMap, of, Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';

import { RefreshErrorToolComponent } from 'src/app/shared/components/refresh-error-tool/refresh-error-tool.component';
import { MonitoringService } from 'src/app/core/services/monitoring.service';
import { Sensor } from 'src/app/shared/models/sensor';
import { Floor } from 'src/app/shared/models/floor';
import { MonitoringTileComponent } from 'src/app/shared/components/monitoring-tile/monitoring-tile.component';
import { RoomsState } from 'src/app/shared/models/state/roomsState';
import { selectSensors } from 'src/app/state/selectors/sensors.selectors';
import { selectRooms } from 'src/app/state/selectors/rooms.selectors';
import { addSelectedRoom, removeSelectedRoom, clearSelectedRooms } from 'src/app/state/actions/rooms.actions';

@Component({
  selector: 'app-monitoring-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, RefreshErrorToolComponent, MonitoringTileComponent]
})
export class RoomsComponent  implements OnInit, OnChanges {

  private monitoringService: MonitoringService = inject(MonitoringService)
  private store = inject(Store)
  private router = inject(Router)
  rooms: Sensor[]
  loadingRooms: boolean
  hasError: boolean
  selectedRooms: Array<number>
  floors: Array<Floor>

  @Input()
  multiSelectionEnabled: boolean

  private destroyed$ = new Subject()

  constructor() {
    this.rooms = []
    this.loadingRooms = false
    this.hasError = false

    this.multiSelectionEnabled = false
    this.selectedRooms = []
    this.floors = []
  }

  ngOnInit() {

    //connect to sensors state
    this.store.pipe(
      takeUntil(this.destroyed$),
      select(selectSensors),
      switchMap((val: any) => {
        this.rooms = val.list || []
        this.loadingRooms = val.isLoading
        this.hasError = val.hasError || false
        this.floors = this.groupRoomsByFloor(val.list || [])
        return of(val)
      })
    ).subscribe()

    //connect to rooms state
    this.store.pipe(
      takeUntil(this.destroyed$),
      select(selectRooms),
      switchMap((val: RoomsState) => {
        this.selectedRooms = val.selectedRooms
        return of(val)
      })
    ).subscribe()

    this.loadRooms()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['multiSelectionEnabled']) {
      let {
        previousValue,
        currentValue
      } = changes['multiSelectionEnabled']

      if ((previousValue !== currentValue) && currentValue === false) {
        //clear multi selection case
        this.store.dispatch(clearSelectedRooms())
      }
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(1)
    this.destroyed$.complete()
  }

  loadRooms() {
    this.monitoringService.loadSensors()
  }

  handlePressRoom(event: any) {
    let room = this.rooms.find(room => room.id === event);
    this.router.navigate(['/wita/appMonitoring/monitoringRoot/sensors/room', { id: room?.id }])
  }

  handleSelectRoom(event: number) {
    let room: any = this.rooms.find(room => room.id === event);
    
    if (this.getSelected(room.id)) {
      this.store.dispatch(removeSelectedRoom({roomId: room.id}))
    } else {
      this.store.dispatch(addSelectedRoom({roomId: room.id}))
    }
  }

  getSelected(room: number) {
    return this.selectedRooms.filter(element => element === room).length > 0
  }

  groupRoomsByFloor(rooms: Array<Sensor>): Array<Floor> {
    const distinctFloors = [...new Set(
      rooms.filter(
        room => (typeof room.floorId == "number")
      ).map(validRoom => validRoom.floorId)
    )]
    return distinctFloors.map((floor = 0) => ({
      id: floor,
      name: "Floor id: " + floor,
      rooms: rooms.filter(room => (room.floorId === floor))
    }))
  }

}
