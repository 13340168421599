import { Routes } from '@angular/router'
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideState } from '@ngrx/store';

import { authGuard } from './core/guards/auth.guard';
import { AuthRoutes } from './pages/auth/auth-root/auth-root.routes';
import { MonitoringRoutes } from './pages/monitoring/monitoring-root/monitoring-root.routes';
import * as fromUserReducer from './state/reducers/user.reducer';
import { witaServiceInterceptor } from './shared/interceptors/wita-service-interceptor';

export const routes: Routes = [
  {
    path: "wita",
    children: [
      {
        path: 'appAuth',
        children: AuthRoutes,
        providers: [
          provideHttpClient(withInterceptors([witaServiceInterceptor]))
        ]
      },
      {
        path: 'appMonitoring',
        children: MonitoringRoutes,
        canActivate: [authGuard],
        providers: [
          provideHttpClient(withInterceptors([witaServiceInterceptor])),
          provideState(fromUserReducer.USER_FEATURE_KEY, fromUserReducer.userReducer),
        ]
      },
      {
        path: '',
        children: AuthRoutes
      },
    ]
  },
  {
    path: '',
    redirectTo: 'wita',
    pathMatch: 'full',
  },
];
