import { createReducer, on } from '@ngrx/store';

import * as UnitsActions from '../actions/units.actions';
import { UnitsState } from 'src/app/shared/models/state/unitsState';

export const UNITS_FEATURE_KEY = 'units';

export const initialUnitsState: UnitsState = {
  data: [],
  isLoading: false,
  hasError: false
};

export const unitsReducer = createReducer(
  initialUnitsState,
  on(UnitsActions.loadingUnits, state => {
    return ({
      ...state,
      data: [],
      isLoading: true,
      hasError: false
    })
  }),
  on(UnitsActions.loadUnitsSuccess, (state, action) => {
    return({
      ...state,
      data: action.units,
      isLoading: false,
      hasError: false
    })
  }),
  on(UnitsActions.loadingUnitsError, state => {
    return ({
      ...state,
      data: [],
      isLoading: false,
      hasError: true
    })
  }),
);
