import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ROOMS_FEATURE_KEY } from '../reducers/rooms.reducer';
import { RoomsState } from 'src/app/shared/models/state/roomsState';

export const selectRoomsState = createFeatureSelector<RoomsState>(ROOMS_FEATURE_KEY);

export const selectRooms = createSelector(
  selectRoomsState,
  (state: RoomsState) => state
);
