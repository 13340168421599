import { Component, EnvironmentInjector, inject } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { MainTopToolbarComponent } from 'src/app/shared/components/main-top-toolbar/main-top-toolbar.component';

@Component({
  selector: 'app-monitoring-tabs',
  templateUrl: 'monitoring-tab-nav.page.html',
  styleUrls: ['monitoring-tab-nav.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, MainTopToolbarComponent],
})
export class MonitoringTabNavPage {
  public environmentInjector = inject(EnvironmentInjector);

  constructor() {}
}
