import { createAction, props } from '@ngrx/store';
import { UserCredentials } from '../../shared/models/user-credentials';
import { User } from 'src/app/shared/models/user';

export const login = createAction(
  '[LOGIN_PAGE] Login',
  props<UserCredentials>()
);

export const initUser = createAction('[WITA] Init user');

export const loadingUser = createAction(
  '[WITA] Loading User data'
);
export const loadUserSuccess = createAction(
  '[WITA] Load User Success',
  props<{ user: User }>()
);
export const loadingUserError = createAction(
  '[WITA] Loading User Error'
);

export const updateUserDevice = createAction(
  '[WITA] Updating User Device'
);

export const updateUserDeviceSuccess = createAction(
  '[WITA] Update User Device Success'
);

export const updateUserDeviceError = createAction(
  '[WITA] Update User Device Error'
);