import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

import { SegmentedButtonsButton } from '../../models/segmented-buttons-button';

@Component({
  selector: 'app-segmented-button',
  templateUrl: './segmented-button.component.html',
  styleUrls: ['./segmented-button.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule]
})
export class SegmentedButtonComponent  implements OnInit {

  @Input()
  buttons: SegmentedButtonsButton[]
  @Input()
  selectedButton: number
  @Output()
  handlePressButton: EventEmitter<any> = new EventEmitter();

  constructor() {
    this.buttons = []
    this.selectedButton = 0
  }

  ngOnInit() {}

  onPressButton(id: number) {
    this.handlePressButton.emit(id)
  }

}
