import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { GenericService } from 'src/app/core/services/generic.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { NavigationTopToolbarComponent } from 'src/app/shared/components/navigation-top-toolbar/navigation-top-toolbar.component';
import { IonicColors } from 'src/app/shared/enums/ionic-colors.enum';
import { SharedModule } from 'src/app/shared/modules/shared.module';

@Component({
  selector: 'app-terms-and-privacy',
  templateUrl: './terms-and-privacy.page.html',
  styleUrls: ['./terms-and-privacy.page.scss'],
  standalone: true,
  imports: [SharedModule, NavigationTopToolbarComponent],
  providers: [GenericService] 
})
export class TermsAndPrivacyPage {
  public isModalOpen = false;
  public modalText: any = '';
  public modalTitle = '';
  constructor(
    private genericService: GenericService, 
    private translateService: TranslateService,
    private toastService: ToastService
  ) { }

  ionViewWillEnter() {}
  
  ionViewWillLeave() {}

  openDoc(docName: 'privacy-policy' | 'cgu' | 'cgs' | boolean) {
    if(!!docName){
      if(docName == 'privacy-policy') this.modalTitle = 'GENERAL.DISCLAIMERS.PRIVACY_POLICY';
      else if(docName == 'cgu') this.modalTitle = 'GENERAL.DISCLAIMERS.CGU';
      else if(docName == 'cgs') this.modalTitle = 'GENERAL.DISCLAIMERS.CGS';

      this.genericService.getTextFile$(`assets/files/disclaimers/${this.translateService?.currentLang || 'it'}/${docName}.txt`)
      .pipe(take(1)).subscribe({
        next: (response) => {
          this.modalText = response?.body;
        },
        error: () => {
          this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.FILENOTFOUND'), IonicColors.RED);
        },
        complete: () => {
          this.isModalOpen = true;
        },
      });
    } else this.isModalOpen = false;
  }

}
