import { FormControl, FormGroup } from "@angular/forms";
import { CustomRegex } from "./custom-regex";

export class CustomValidator {
    /**
     * @description Dato un formGroup controlla che il campo password e confirmPassword coincidino
     * @param group 
     * @returns Se tutto OK ritorna null altrimenti torna un oggetto di tipo ValidatorResponse
     */
    static matchPassword(group: FormGroup): ValidatorResponse | null {
       const password = group.controls['password'];
       const confirmPassword = group.controls['confirmPassword'];
       if (password.pristine || confirmPassword.pristine) {
          return null;
       }
       group.markAsTouched();
       if (password.value === confirmPassword.value) {
          return null;
       }
       return {
          invalidPassword: true
       };
    }
    /**
     * @description Validatore che controlla che lo username rispetti le regole di validazione
     * @param username Formcontrol del campo Username 
     * @returns Se tutto OK ritorna null altrimenti torna un oggetto di tipo ValidatorResponse
     */
    static usernameValidator(username: FormControl): ValidatorResponse | null {
       if (username.pristine) {
          return null;
       }
       username.markAsTouched();
       if (CustomRegex.USERNAME_VALIDATOR.test(username.value)) {
          return null;
       }
       return {
          invalidUsername: true
       };
    }

    /**
     * @description Validatore che controlla che la password rispetti le regole di validazione
     * @param password Formcontrol del campo Password 
     * @returns Se tutto OK ritorna null altrimenti torna un oggetto di tipo ValidatorResponse
     */
    static passwordValidator(password: FormControl): ValidatorResponse | null {
      if (password.pristine) {
         return null;
      }
      password.markAsTouched();
      if (CustomRegex.PASSWORD_VALIDATOR.test(password.value)) {
         return null;
      }
      return {
         invalidPassword: true
      };
   }

   /**
     * @description Validatore che controlla che l'email rispetti le regole di validazione
     * @param email Formcontrol del campo Email 
     * @returns Se tutto OK ritorna null altrimenti torna un oggetto di tipo ValidatorResponse
     */
   static emailValidator(email: FormControl): ValidatorResponse | null {
      if (email.pristine) {
         return null;
      }
      email.markAsTouched();
      if (CustomRegex.EMAIL_VALIDATOR.test(email.value)) {
         return null;
      }
      return {
         invalidEmail: true
      };
   }
}

interface ValidatorResponse {
    [key: string]: boolean
}