import { InjectionToken, enableProdMode, importProvidersFrom, isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideServiceWorker } from '@angular/service-worker';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { storeWita } from './app/state/wita.store';
import { witaStoreDevToolsConfig } from './app/state/wita.store-dev-tools-config';
import { LogLevel } from './app/core/services/logger.service';
import { provideAnimations } from '@angular/platform-browser/animations';


if (environment.production) {
  enableProdMode();
}

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const MIN_LOG_LEVEL = new InjectionToken<LogLevel>("Minimum log level");

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: MIN_LOG_LEVEL,
      useValue: LogLevel.DEBUG//isDevMode() ? LogLevel.INFO : LogLevel.NEVER,
    },
    { 
      provide: RouteReuseStrategy, 
      useClass: IonicRouteStrategy 
    },
    provideRouter(routes),
    // Setup NGRX:
    provideStore(storeWita),
    provideStoreDevtools(witaStoreDevToolsConfig),
    provideHttpClient(),
    // Import Providers
    importProvidersFrom(
      IonicModule.forRoot({
        mode: "ios",
      }),
      // Ngx Translate
      TranslateModule.forRoot({
        defaultLanguage: 'it',
        loader: {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [HttpClient],
        },
      })
    ),
    provideAnimations(),
    // Setup Service Worker
    provideServiceWorker('ngsw-worker.js', {
        enabled: true, //!isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    })
],
}).catch((err) => console.error(err));
