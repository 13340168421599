<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title>{{'PAGES.DISCLAIMER_REQUEST.TITLE' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <!-- SEGMENTI DOCUMENTI -->
  <div class="segments">
    <ion-segment [(ngModel)]="selectedSegmentIndex" mode="md">
      <ion-segment-button [value]="i" [disabled]="disclaimer.disabled" *ngFor="let disclaimer of disclaimersData; index as i;" (click)="scrollDocumentToTop()">
          <ion-label>{{disclaimer.label | translate}}</ion-label>
      </ion-segment-button>
    </ion-segment>
  </div>
  <!-- TESTO DOCUMENTO SELEZIONATO + CHECKBOX ACCETTAZIONE -->
  <div class="document">
    <ion-content
      #disclaimerText
      [scrollEvents]="true"
      (ionScroll)="scrolling(selectedSegmentIndex)"
    >
      <!-- TESTO -->
      <pre>
        {{disclaimersData[selectedSegmentIndex].text}}
      </pre>
      <!-- ACCETTAZIONE -->
      <div class="document_checkbox" *ngIf="selectedSegmentIndex !== 2">
        <ion-checkbox [(ngModel)]="disclaimersData[selectedSegmentIndex].accepted">{{'GENERAL.COMMON.ACCEPT' | translate}}</ion-checkbox>
      </div>
    </ion-content>
  </div>
  <!-- CTA -->
  <div class="buttons">
    <ion-button (click)="logout()" color="medium">
      {{'GENERAL.COMMON.LOGOUT' | translate}}
    </ion-button>
    <ion-button (click)="submitDisclaimer()" [disabled]="disableSubmit">
      {{'GENERAL.COMMON.PROCEED' | translate}}
    </ion-button>
  </div>
</ion-content>
