import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs';
import { Store } from '@ngrx/store';

import { environment } from 'src/environments/environment';
import { loadingOrganizations, loadOrganizationsSuccess, loadingOrganizationsError } from 'src/app/state/actions/organizations.actions';
import { loadingUnits, loadUnitsSuccess, loadingUnitsError } from 'src/app/state/actions/units.actions';

@Injectable({
  providedIn: 'any'
})
export class InfrastructureService {

  private _http = inject(HttpClient)
  private store = inject(Store)

  constructor() {}

  public loadOrganizations() {

    this.store.dispatch(loadingOrganizations())

    return this._http.get(
      `${environment.apiUrl}/user-info/organizations`
    ).pipe(
      tap((val: any) => {
        this.store.dispatch(loadOrganizationsSuccess({organizations: val.data}))
      }),
      catchError(err => {
        this.store.dispatch(loadingOrganizationsError())
        throw 'error in source. Details: ' + err;
      })
    ).subscribe()

  }

  /**
   * loadUserUnits
   */
  public loadUserUnits() {
    
    this.store.dispatch(loadingUnits())

    return this._http.get(
      `${environment.apiUrl}/user-info/units`
    ).pipe(
      tap((val: any) => {
        //@TODO aspettare che funzionino i servizi, risponde not yet implemented
        this.store.dispatch(loadUnitsSuccess({units: val.data}))
      }),
      catchError(err => {
        this.store.dispatch(loadingUnitsError())
        throw 'error in source. Details: ' + err;
      })
    ).subscribe()
    
  }

}
