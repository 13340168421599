<div
  [ngClass]="['tile-container', this.showBodyInfos ? '' : 'hidden-body-infos']"
  (click)="this.tilesSelectionMode ? checkmarkElement() : onPressTile($event)"
>
  <div *ngIf="this.enableVisualMonitoring">
    <img src="https://img.freepik.com/free-photo/people-colorful-thermal-scan-with-celsius-degree-temperature_23-2149170134.jpg?w=2000&t=st=1690811440~exp=1690812040~hmac=36dcda0145fd15136451354ffc6f27aa590588d23f4866bf5288d13fcf14af55" [ngClass]="'streaming-sensor-img'"/>
  </div>
  
  <div [ngClass]="'tile-info-outer-container'">
    <div [ngClass]="'tile-info-header-container'">
      <ion-label [ngClass]="'label-title'">
        <ion-icon
          *ngIf="!this.tileData.isOnline"
          name="pause-circle"
          [ngClass]="['icon-sensor-offline']"
          size="small"
        ></ion-icon>
        {{this.tileData.label}}
      </ion-label>
    </div>
    <div
      *ngIf="showBodyInfos"
      [ngClass]="'tile-info-body-container'"
    >
      <ion-label [ngClass]="'label-patient-name'">
        {{this.tileData.visitorName}}
      </ion-label> 
      <ion-label [ngClass]="'label-unit'">
        {{this.tileData.unit}}
      </ion-label>
    </div>
  </div>

  <div *ngIf="!this.tilesSelectionMode" [ngClass]="'tile-info-extra-container'">
    <div *ngIf="this.tileData.pinned" [ngClass]="['pin-container-outer']">
      <div [ngClass]="['pin-container-triangle']"></div>
      <a [ngClass]="['pin-clickable']" (click)="clickPin($event)">
        <ion-icon class="pin-component" name="eye" [ngStyle]="{'width': '16px', 'height': '16px'}"></ion-icon>
      </a>
    </div>
    <ion-label *ngIf="this.tileData.eventTime.length > 0" [ngClass]="'label-event-time'" >
      {{this.tileData.eventTime}}
    </ion-label>
    <ion-icon name="{{this.tileData.riskStatusLevelIcon}}" [ngClass]="['icon-risk-category']" size="large"></ion-icon>
  </div>

  <div *ngIf="this.tilesSelectionMode" [ngClass]="'tile-info-extra-container'" [ngStyle]="{'width': '-webkit-fill-available'}">
    <div [ngClass]="['checkmark-container']">
      <a *ngIf="this.isSelected" [ngClass]="['checkmark-icon-outer']">
        <ion-icon name="checkmark-sharp" [ngStyle]="{'width': '14px', 'height': '14px'}" color="light"></ion-icon>
      </a>
    </div>
  </div>
  
</div>
