import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';

import { NavigationTopToolbarComponent } from 'src/app/shared/components/navigation-top-toolbar/navigation-top-toolbar.component';
import { CameraScreenDetailedComponent } from 'src/app/shared/components/camera-screen-detailed/camera-screen-detailed.component';

@Component({
  selector: 'app-bed-detail',
  templateUrl: './bed-detail.page.html',
  styleUrls: ['./bed-detail.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, NavigationTopToolbarComponent, CameraScreenDetailedComponent]
})
export class BedDetailPage implements OnInit {

  private router: Router = inject(Router)
  private route: ActivatedRoute = inject(ActivatedRoute)
  bedId: string | null

  constructor() {
    this.bedId = null
  }

  ngOnInit() {
    let bedIdFromRouter = this.route.snapshot.paramMap.get('id')
    if (bedIdFromRouter) {
      this.bedId = bedIdFromRouter;
    }
  }

}
