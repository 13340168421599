<ion-content color="light">
  <app-refresh-error-tool
    *ngIf="!this.multiSelectionEnabled"
    [hasError]="this.hasError"
    [isLoading]="this.loadingRooms"
    [handleRefreshCallbackFn]="this.loadRooms.bind(this)"
    [loadRetryFn]="this.loadRooms.bind(this)"
  ></app-refresh-error-tool>
  <div
    *ngFor="let floor of floors; let i = index"
  >
    <div [ngClass]="'grouped-tiles-header'">
      <ion-label [ngClass]="'floor-name-label'">{{floor.name}}</ion-label>
    </div>
    <div
      [ngClass]="'grouped-tiles-container'"
    >
      <!-- <app-monitoring-tile
        *ngFor="let room of floor.rooms; let i = index"
        [tileData]="room"
        [tilesSelectionMode]="this.multiSelectionEnabled"
        [showBodyInfos]="false"
        [isSelected]="getSelected(room.id)"
        (handlePressTile)="handlePressRoom($event)"
        (handleSelectTile)="handleSelectRoom($event)"
        [ngClass]="'room-tile'"
      ></app-monitoring-tile> -->
    </div>
  </div>
</ion-content>
