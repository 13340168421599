import { Injectable, inject } from '@angular/core';
import { MIN_LOG_LEVEL } from 'src/main';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  readonly #minLogLevel = inject(MIN_LOG_LEVEL) ?? LogLevel.NEVER;
  logWithDate: boolean = true;

  constructor() { }

  /**
   * @description Controlla se è possibile loggare il livello passato in Input basato su quello trovato nel main.ts
   * @param logLevel Livello del log che si desidera mostrare
   * @returns Torna un booleano, true se si è abilitati a vedere il log, altrimenti false
   */
  #canLog(logLevel: LogLevel): boolean {
    return logLevel >= this.#minLogLevel;
  }

  debug(msg: string) {
    if (!this.#canLog(LogLevel.DEBUG)) return;
    this.writeToLog(msg, LogLevel.DEBUG);
  }

  info(msg: string) {
    if (!this.#canLog(LogLevel.INFO)) return;
    this.writeToLog(msg, LogLevel.INFO);
  }

  warn(msg: string) {
    if (!this.#canLog(LogLevel.WARN)) return;
    this.writeToLog(msg, LogLevel.WARN);
  }

  error(msg: string) {
    if (!this.#canLog(LogLevel.ERROR)) return;
    this.writeToLog(msg, LogLevel.ERROR);
  }

  fatal(msg: string) {
    if (!this.#canLog(LogLevel.FATAL)) return;
    this.writeToLog(msg, LogLevel.FATAL);
  }

  /**
   * @description Funzione che si occupa di scrivere il log
   * @param msg Messaggio da scrivere nel log
   * @param level Severity del log
   */
  private writeToLog(msg: string, level: number) {
    let value: string = "";
    // Se la scrittura data è abilitata la mostro nel log
    if (this.logWithDate) {
        value = `[${new Date().toLocaleString()}] - `;
    }
    value += `${level === 5 ? '💀' : ''}[%c${LogLevel[level]}%c]${level === 5 ? '💀' : ''}`;
    value += " - " + msg;
    // Stampo il console log
    console.log(value, `color: ${LogLevelConsoleColors[level-1]}`, 'color: white');
  }
}

/**
 * @description Enum contenente tutti i livelli di Log, NOTA BENE: il NEVER DEVE essere sempre l'ultimo
 */
export enum LogLevel {
  /**
   * @description Informazioni utili al fine di debuggare
   */
  DEBUG = 1,
  /**
   * @description Informazioni generali
   */
  INFO = 2,
  /**
   * @description Anomalia o potenziale errore
   */
  WARN = 3,
  /**
   * @description Errore generico
   */
  ERROR = 4,
  /**
   * @description Errore critico
   */
  FATAL = 5,
  /**
   * @description Disabilitazione dei log
   */
  NEVER = 6
}

/**
 * @description Colore del tipo di console log basato sull'enum LogLevel
 */
enum LogLevelConsoleColors {
  "#abcdef", // DEBUG
  "#874dbf", // INFO
  "yellow",  // WARN
  "red",     // ERROR
  "black"    // FATAL
}
