<app-navigation-top-toolbar
  [title]="'Strutture monitorate'"
></app-navigation-top-toolbar>
<ion-content color="light">
  <app-refresh-error-tool
    [hasError]="this.hasError"
    [isLoading]="this.loadingPageData"
    [handleRefreshCallbackFn]="this.loadPageData.bind(this)"
    [loadRetryFn]="this.loadPageData.bind(this)"
  ></app-refresh-error-tool>
  <div *ngIf="this.loadingPageData === false" [ngClass]="'settings-items-container'">
    <ion-label [ngClass]="'settings-grouping-title'">SELEZIONA STRUTTURA</ion-label>
    <ion-item
      button
      detail="true"
      lines="none"
      [ngClass]="'settings-item-button'"
      [ngStyle]="{'margin-bottom': '35px'}"
      (click)="onPressSelectInfrastructure()"
    >
      <div [ngClass]="'select-structure-button-inner'">
        <ion-label>Struttura</ion-label>
        <ion-label [ngClass]="'structure-name-label'">{{selectedStructureName}}</ion-label>
      </div>
    </ion-item>
    <ion-label [ngClass]="'settings-grouping-title'">UNITÀ MONITORATE</ion-label>
    <ion-list [inset]="true" [ngClass]="'settings-list-items'">
      <ion-item
        *ngFor="let unit of this.unitsList; let i = index;"
      >
        <ion-toggle [checked]="unit.checked" (ionChange)="onToggleUnit(unit.value)">{{unit.text}}</ion-toggle>
      </ion-item>
    </ion-list>
  </div>
</ion-content>