import { Action, createReducer, on } from '@ngrx/store';

import * as UserActions from '../actions/user.actions';
import { UserState } from 'src/app/shared/models/state/userState';

export const USER_FEATURE_KEY = 'user';

export const initialUserState: UserState = {
  data: null,
  isLoading: false,
  hasError: false
};

export const userReducer = createReducer(
  initialUserState,
  on(UserActions.loadingUser, state => {
    return ({
      ...state,
      data: null,
      isLoading: true,
      hasError: false
    })
  }),
  on(UserActions.loadUserSuccess, (state, action) => {
    return ({
      ...state,
      data: action.user,
      isLoading: false,
      hasError: false
    })
  }),
  on(UserActions.loadingUserError, state => {
    return ({
      ...state,
      data: null,
      isLoading: false,
      hasError: true
    })
  }),
);