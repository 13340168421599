import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { AuthService } from 'src/app/core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { UserCredentials } from 'src/app/shared/models/user-credentials';
import { take } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { IonicColors } from 'src/app/shared/enums/ionic-colors.enum';
import { CustomValidator } from 'src/app/shared/utils/custom-validators';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { LoggerService } from 'src/app/core/services/logger.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MaterialModule
  ]
})
export class LoginPage implements OnInit {
  public showPassword: boolean = false;
  public selectedLang: 'it' | 'en' | 'fr' = 'it';
  public countriesList: Array<{id: 'it' | 'en' | 'fr', path: string}> = [
    {id: 'it', path: '../../../../assets/files/flags/it.svg'},
    {id: 'en', path: '../../../../assets/files/flags/en.svg'},
    {id: 'fr', path: '../../../../assets/files/flags/fr.svg'}
  ]
  authForm = new FormGroup<any>({});
  resetPwForm = new FormGroup<any>({});
  constructor(
    private translateService: TranslateService,
    private toastService: ToastService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private loggerService: LoggerService
  ) {
  }
  
  isSubmitted: boolean = false;
  public hasForgotPw: boolean = false;

  ngOnInit(): void {
    //this.router.navigate(['/wita/auth/reset-password']);
    this.authForm = this.formBuilder.group({
      username: ['', [Validators.required, CustomValidator.usernameValidator]],
      password: ['', [Validators.required]]
    });
    this.resetPwForm = this.formBuilder.group({
      email: ['', [Validators.required, CustomValidator.emailValidator]]
    });
    this.selectedLang = this.translateService.currentLang as 'it' | 'en' | 'fr' || 'it';
  }

  /**
   * @description Funzione invocata al click di Reimposta, dopo aver fatto 'password dimenticata?'
   */
  submitMail() {
    if (this.emailFormControls['email']['errors'] || this.resetPwForm.invalid) {
      this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.UNCORRECT_FIELDS_COMPILATION'), IonicColors.RED);
      return;
    }
    const emailObject = {
      email: this.resetPwForm.controls['email'].value,
      type: '10'
    };
    this.authService.resetPassword$(emailObject)
      .pipe(take(1))
      .subscribe({
        complete: () => {
          this.toastService.showToast(this.translateService.instant('GENERAL.SUCCESS.EMAIL_SENT'), IonicColors.GREEN, 6000);
          this.hasForgotPw = false;
        },
        error: () => {
          this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.UNKNOWN_EMAIL'), IonicColors.RED);
        }
      });
  }

  /**
   * @description Funzione invocata al click di Login
   */
  onClickLogin() {
    this.isSubmitted = true;
    if (this.authFormControls['username']['errors'] || this.authFormControls['password']['errors'] || this.authForm.invalid) {
      this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.UNCORRECT_FIELDS_COMPILATION'), IonicColors.RED);
      return;
    }
    let {username,password}: UserCredentials = this.authForm["value"];
    this.authService.login(
      {
        username,
        password,
      },
      this.selectedLang,
      () => this.toastService.showToast(this.translateService.instant('GENERAL.ERRORS.WRONG_CREDENTIALS'), IonicColors.RED)
    );
  }

  /**
   * @description Funzione invocata al cambiamento della lingua, vengono impostate le traduzioni di conseguenza
   */
  onLanguageSelect(language: 'it' | 'en' | 'fr') {
    this.translateService.use(language);
    this.loggerService.debug(`Selezionata lingua: ${language}`);
  }

  /**
   * @description Funzione invocata al click del pulsante indietro, resetta l'input di inserimento email
   */
  onBack() {
    this.hasForgotPw = false; 
    this.emailFormControls['email'].reset();
  }

  get authFormControls() {
    return this.authForm.controls
  }

  get emailFormControls() {
    return this.resetPwForm.controls
  }

  get selectedLangObject() {
    return this.countriesList.find((country) => country.id === this.selectedLang)
  }
}
