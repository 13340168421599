<ion-menu contentId="monitoring-root">
  <ion-header>
    <ion-toolbar [ngClass]="['side-menu-toolbar']">
      <ion-menu-toggle slot="end">
        <ion-icon name="close-outline" size="large" class="icon-close-menu"></ion-icon>
      </ion-menu-toggle>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-item [ngClass]="['no-padding']" lines="none">
      <app-user-info
        *ngIf="this.userData"
        [data]="this.userData"
        [ngClass]="['user-info']"
      ></app-user-info>
      <app-retry-error-tool-small
        [isLoading]="this.isLoading"
        [hasError]="this.hasError"
        [loadRetryFn]="this.loadUserInfos.bind(this)"
        [errorText]="'Errore caricamento dati utente loggato...'"
        [ngClass]="'retry-error-tool'"
      ></app-retry-error-tool-small>
    </ion-item>
    <ion-list lines="none" *ngFor="let actionButton of actionButtons; let i = index">
      <app-action-button
        [actionButton]="this.actionButton"
        [callbackFn]="this.actionButtonCallbackFn.bind(this)"
      ></app-action-button>
    </ion-list>
  </ion-content>
</ion-menu>
