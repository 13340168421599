import { Component, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, filter, of, take, takeWhile, tap, throwError } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { NavigationTopToolbarComponent } from 'src/app/shared/components/navigation-top-toolbar/navigation-top-toolbar.component';
import * as fromUserSelectors from "../../../state/selectors/user.selectors"
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { UserState } from 'src/app/shared/models/state/userState';
import { User } from 'src/app/shared/models/user';
import { ToastService } from 'src/app/core/services/toast.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { IonModal } from '@ionic/angular';
import { LocalUserDevices } from 'src/app/shared/models/user-devices';
import { IonicColors } from 'src/app/shared/enums/ionic-colors.enum';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.page.html',
  styleUrls: ['./account-settings.page.scss'],
  standalone: true,
  imports: [SharedModule, ReactiveFormsModule, MaterialModule, NavigationTopToolbarComponent]
})
export class AccountSettingsPage {
  @ViewChild(IonModal) modal!: IonModal;
  public isAlive = true;
  private store = inject(Store);
  user!: User | null;
  public userDevices: Array<LocalUserDevices> = [];
  private formBuilder: FormBuilder = inject(FormBuilder);
  accountSettingsFg!: FormGroup;
  isSubmitted = false;
  formDisabled = true;
  constructor(
    public translateService: TranslateService,
    private authService: AuthService,
    private userService: UserService,
    private toastService: ToastService,
    private loggerService: LoggerService,
    private alertService: AlertService) { }
 
  ionViewWillEnter() {
    this.isAlive = true;
    this.accountSettingsFg = this.formBuilder.group({
      email: [{value: '', disabled: this.formDisabled}, Validators.required],
      firstname: [{value: '', disabled: this.formDisabled}, Validators.required, Validators.minLength(2)],
      lastname: [{value: '', disabled: this.formDisabled}, Validators.required, Validators.minLength(2)]
    });
    this.store.pipe(
      select(fromUserSelectors.selectUser),
      filter(data => !!data?.data),
      takeWhile(() => this.isAlive)
    ).subscribe((data: UserState) => {
      this.user = data?.data;
      this.accountSettingsFg.controls['email'].setValue(this.user?.email);
      this.accountSettingsFg.controls['firstname'].setValue(this.user?.firstname);
      this.accountSettingsFg.controls['lastname'].setValue(this.user?.lastname);
    });
    this.userService.getUserDevices$().pipe(
      take(1),
      tap((res) => {
        this.userDevices = res;
      }
    )).subscribe();
  }

  get formControls() {
    return this.accountSettingsFg.controls;
  }

  ionViewWillLeave() {
    this.isAlive = false;
  }

  requestAction(action: 'resetPassword' | 'disconnectDevice' | 'disconnectAllDevices' | 'logout' | 'deleteAccount', optionalData?: LocalUserDevices){
    let dialogTitle = '', dialogText = '',
      errorMsg = 'GENERAL.ERRORS.GENERIC_ERROR', successMsg = 'GENERAL.SUCCESS.OPERATION_DONE_SUCCESFULLY';
    switch(action){
      case 'resetPassword':
        dialogTitle = this.translateService.instant('PAGES.SETTINGS.ACCOUNT_SETTINGS.RESET_PASSWORD_WARNING_TITLE');
        dialogText = this.translateService.instant('PAGES.SETTINGS.ACCOUNT_SETTINGS.RESET_PASSWORD_WARNING_MESSAGE');
        break;
      case 'logout':
        dialogTitle = this.translateService.instant('PAGES.SETTINGS.ACCOUNT_SETTINGS.LOGOUT_WARNING_TITLE');
        dialogText = this.translateService.instant('PAGES.SETTINGS.ACCOUNT_SETTINGS.LOGOUT_WARNING_MESSAGE');
        break;
      case 'deleteAccount':
        dialogTitle = this.translateService.instant('PAGES.SETTINGS.ACCOUNT_SETTINGS.DELETE_ACCOUNT_WARNING_TITLE');
        dialogText = this.translateService.instant('PAGES.SETTINGS.ACCOUNT_SETTINGS.DELETE_ACCOUNT_WARNING_MESSAGE');
        break;
      case 'disconnectDevice':
        dialogTitle = this.translateService.instant('PAGES.SETTINGS.ACCOUNT_SETTINGS.DELETE_ACCOUNT_WARNING_TITLE');
        dialogText = this.translateService.instant('PAGES.SETTINGS.ACCOUNT_SETTINGS.DELETE_ACCOUNT_WARNING_MESSAGE');
        break;
      case 'disconnectAllDevices':
        dialogTitle = this.translateService.instant('PAGES.SETTINGS.ACCOUNT_SETTINGS.DISCONNECT_ALL_USER_DEVICES_WARNING_TITLE');
        dialogText = this.translateService.instant('PAGES.SETTINGS.ACCOUNT_SETTINGS.DISCONNECT_ALL_USER_DEVICES_WARNING_MESSAGE');
        break;
    }
    this.alertService.presentAlert(dialogTitle, dialogText);
    this.alertService.handlerResponse.pipe(
      take(1),
      tap((response) => {
        if (response) {
          let action$ = of({});
          switch(action){
            case 'resetPassword':
              const emailObject = {
                email: this.user?.email || "",
                type: '10'
              };
              action$ = this.authService.resetPassword$(emailObject);
              break;
            case 'disconnectDevice':
              action$ = this.authService.disconnectDevice$(optionalData!.deviceId, optionalData!.token);
              break;
            case 'disconnectAllDevices':
              action$ = this.authService.disconnectAllUserDevices$();
              break;
            case 'logout':
              action$ = this.authService.logout$();
              break;
            case 'deleteAccount':
              action$ = this.authService.deleteAccount$();
              break;
          }
          action$.pipe(
            takeWhile(() => this.isAlive),
            tap(() => {
              this.toastService.showToast(this.translateService.instant(successMsg))
            }),
            catchError((err) => {
              this.toastService.showToast(this.translateService.instant(errorMsg), IonicColors.RED);
              return throwError(() => err);
            }))
          .subscribe();
        }
      })).subscribe();
  }

  closeModal() {
    this.modal.dismiss(null, 'closed')
  }

  onWillDismiss(event: Event) {
    /* const ev = event as CustomEvent<OverlayEventDetail<string>>;
    if (ev.detail.role === 'confirm') {
      this.message = `Hello, ${ev.detail.data}!`;
    } */
  }
}
