import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-sensors-stack-nav',
  templateUrl: './sensors-stack-nav.page.html',
  styleUrls: ['./sensors-stack-nav.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule]
})
export class SensorsStackNavPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
