import { Component, inject, OnInit, OnChanges } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { switchMap, of, Subject, takeUntil } from 'rxjs';

import { selectSelectedBeds } from 'src/app/state/selectors/beds.selectors';
import { clearSelectedBeds } from 'src/app/state/actions/beds.actions';
import { selectRooms } from 'src/app/state/selectors/rooms.selectors';
import { clearSelectedRooms } from 'src/app/state/actions/rooms.actions';
import { BedsComponent } from 'src/app/components/monitoring/beds/beds.component';
import { RoomsComponent } from 'src/app/components/monitoring/rooms/rooms.component';
import { OverviewMode } from 'src/app/shared/enums/overview-mode';

@Component({
  selector: 'app-overviewtab',
  templateUrl: 'overview-tab.page.html',
  styleUrls: ['overview-tab.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, BedsComponent, RoomsComponent]
})

export class OverviewTabPage {

  multiSelectionEnabled: boolean;
  overviewMode: OverviewMode
  selectedItems: Array<string>

  constructor() {
    this.multiSelectionEnabled = false
    this.overviewMode = this.initOverviewMode()
    this.selectedItems = []
  }

  private router = inject(Router)
  private route: ActivatedRoute = inject(ActivatedRoute)
  private store = inject(Store)

  private destroyed$ = new Subject()

  ngOnInit() {

    this.store.pipe(
      takeUntil(this.destroyed$),
      select(selectSelectedBeds),
      switchMap(val => {
        this.selectedItems = val.selectedBeds
        return of(val)
      })
    ).subscribe()

    /** TODO: fix after room object review */
    // this.store.pipe(
    //   takeUntil(this.destroyed$),
    //   select(selectRooms),
    //   switchMap(val => {
    //     this.selectedItems = val.selectedRooms
    //     return of(val)
    //   })
    // ).subscribe()

  }

  ngOnDestroy() {
    this.destroyed$.next(1)
    this.destroyed$.complete()
  }

  enableSensorsSelection() {
    this.multiSelectionEnabled = !this.multiSelectionEnabled
  }

  overviewModeChange(event: any) {
    let mode = event.detail.value
    if (mode === "beds") {
      this.store.dispatch(clearSelectedRooms())
    } else if (mode === "rooms") {
      this.store.dispatch(clearSelectedBeds())
    }

    this.overviewMode = mode
  }

  initOverviewMode(): OverviewMode {
    /**
     * First try to retrieve mode from route, else set Beds by default
     */
    let overviewModeFromRouter = this.route.snapshot.paramMap.get('mode')
    let indexOfOverviewModeFromRouter = Object.values(OverviewMode).findIndex(el => el === overviewModeFromRouter)
    if (overviewModeFromRouter && indexOfOverviewModeFromRouter >= 0) {
      const key = Object.values(OverviewMode)[indexOfOverviewModeFromRouter] as OverviewMode;
      return key
    } else return OverviewMode.Beds
  }

}
