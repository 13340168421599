import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { switchMap, of, Subject, takeUntil } from 'rxjs';

import { NavigationTopToolbarComponent } from 'src/app/shared/components/navigation-top-toolbar/navigation-top-toolbar.component';
import { ListOptionsElement } from 'src/app/shared/models/list-options-element';
import { InfrastructureService } from 'src/app/core/services/infrastructure.service';
import { Organization } from 'src/app/shared/models/organization';
import { UserService } from 'src/app/core/services/user.service';
import {
  selectSelectedUserOrganizationDetails,
  pageInfrastructureSettingsStatus
} from 'src/app/state/selectors/infrastructure.selectors';
import { RefreshErrorToolComponent } from 'src/app/shared/components/refresh-error-tool/refresh-error-tool.component';
import { GeneralPageState } from 'src/app/shared/models/state/generalPageState';
import { selectUnits } from 'src/app/state/selectors/units.selectors';
import { UnitsState } from 'src/app/shared/models/state/unitsState';
import { SharedModule } from 'src/app/shared/modules/shared.module';

@Component({
  selector: 'app-infrastructure-settings',
  templateUrl: './infrastructure-settings.page.html',
  styleUrls: ['./infrastructure-settings.page.scss'],
  standalone: true,
  imports: [SharedModule, NavigationTopToolbarComponent, RefreshErrorToolComponent]
})
export class InfrastructureSettingsPage {

  private router: Router = inject(Router);

  selectedStructureName: string;
  unitsList: ListOptionsElement[];
  loadingPageData: boolean;
  hasError: boolean;

  constructor() {
    this.selectedStructureName = "";
    this.loadingPageData = false;
    this.hasError = false;

    this.unitsList = [
      {
        value: "0",
        text: "Piano Terra",
        checked: true
      },
      {
        value: "1",
        text: "Piano 1",
        checked: false
      },
      {
        value: "2",
        text: "Piano 2",
        checked: false
      },
      {
        value: "3",
        text: "Palazzina annessa",
        checked: true
      }
    ];
  }

  private userService: UserService = inject(UserService);
  private infrastructureService: InfrastructureService = inject(InfrastructureService);
  private store = inject(Store);

  private destroyed$ = new Subject();

  ionViewWillEnter() {
    //connect to user state
    this.store.pipe(
      takeUntil(this.destroyed$),
      select(selectSelectedUserOrganizationDetails),
      switchMap((val: Array<Organization>) => {
        if (val.length > 0) {
          this.selectedStructureName = val[0].name
        } else this.selectedStructureName = ""
        return of(val);
      })
    ).subscribe();

    //connect to units state
    this.store.pipe(
      takeUntil(this.destroyed$),
      select(selectUnits),
      switchMap((val: UnitsState) => {
        //@TODO aspettare che funzionino i servizi
        return of(val);
      })
    );

    this.store.pipe(
      select(pageInfrastructureSettingsStatus),
      switchMap((val: GeneralPageState) => {
        this.loadingPageData = val['isLoading']
        this.hasError = val['hasError']
        return of(val);
      })
    ).subscribe();

    this.loadPageData();
  }

  ionViewWillLeave() {
    this.destroyed$.next(1);
    this.destroyed$.complete();
  }

  onPressSelectInfrastructure() {
    this.router.navigate(['/wita/appMonitoring/monitoringRoot/settings/infrastructureSelect']);
  }

  onToggleUnit(value: string) {
    let unit = this.unitsList.filter(unit => unit.value === value)[0];
    unit['checked'] = !unit.checked;
  }

  loadPageData() {
    this.userService.loadUserInfos();
    this.infrastructureService.loadOrganizations();
    this.infrastructureService.loadUserUnits();
  }

}
